/* tslint:disable */
/* eslint-disable */
/**
 * Wear Management API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 * @type PutRegionsRegionidRequest
 * see RHIM.APO.Common.Data.Reporting.Region
 * @export
 */
export type PutRegionsRegionidRequest = RHIMAPOReportingWearManagementApiV1ModelsRegionCreateRequestDto | RHIMAPOReportingWearManagementApiV1ModelsRegionUpdateRequestDto;

/**
 * 
 * @export
 * @enum {string}
 */

export const RHIMAPOCommonDataReportingColorScaleType = {
    Rbl: 'RBL',
    Wear: 'Wear'
} as const;

export type RHIMAPOCommonDataReportingColorScaleType = typeof RHIMAPOCommonDataReportingColorScaleType[keyof typeof RHIMAPOCommonDataReportingColorScaleType];


/**
 * 
 * @export
 * @enum {string}
 */

export const RHIMAPOCommonDataReportingCoordinateSystem2D = {
    Xy: 'XY',
    RTheta: 'RTheta',
    ThetaZ: 'ThetaZ'
} as const;

export type RHIMAPOCommonDataReportingCoordinateSystem2D = typeof RHIMAPOCommonDataReportingCoordinateSystem2D[keyof typeof RHIMAPOCommonDataReportingCoordinateSystem2D];


/**
 * 
 * @export
 * @enum {string}
 */

export const RHIMAPOCommonDataReportingFunctionalProductType = {
    Taphole: 'Taphole',
    PurgePlug: 'PurgePlug',
    Trunnion: 'Trunnion',
    LooseTyre: 'LooseTyre',
    FixedTyre: 'FixedTyre',
    GirthGear: 'GirthGear'
} as const;

export type RHIMAPOCommonDataReportingFunctionalProductType = typeof RHIMAPOCommonDataReportingFunctionalProductType[keyof typeof RHIMAPOCommonDataReportingFunctionalProductType];


/**
 * 
 * @export
 * @enum {string}
 */

export const RHIMAPOCommonDataReportingHostingMode = {
    AmlWithAksFallback: 'AMLWithAKSFallback',
    Aml: 'AML',
    Aks: 'AKS',
    Disabled: 'Disabled'
} as const;

export type RHIMAPOCommonDataReportingHostingMode = typeof RHIMAPOCommonDataReportingHostingMode[keyof typeof RHIMAPOCommonDataReportingHostingMode];


/**
 * 
 * @export
 * @interface RHIMAPOCommonDataReportingRegionPoint
 */
export interface RHIMAPOCommonDataReportingRegionPoint {
    /**
     * 
     * @type {number}
     * @memberof RHIMAPOCommonDataReportingRegionPoint
     */
    'r'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RHIMAPOCommonDataReportingRegionPoint
     */
    'theta'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RHIMAPOCommonDataReportingRegionPoint
     */
    'z'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RHIMAPOCommonDataReportingRegionPoint
     */
    'x'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RHIMAPOCommonDataReportingRegionPoint
     */
    'y'?: number | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const RHIMAPOCommonDataReportingRegionTag = {
    None: 'None',
    OperatorView: 'OperatorView',
    MeasurementView: 'MeasurementView',
    Research: 'Research',
    WearPredictions: 'WearPredictions',
    TemperaturePredictions: 'TemperaturePredictions'
} as const;

export type RHIMAPOCommonDataReportingRegionTag = typeof RHIMAPOCommonDataReportingRegionTag[keyof typeof RHIMAPOCommonDataReportingRegionTag];


/**
 * 
 * @export
 * @interface RHIMAPOReportingWearManagementApiV1ModelsAbsoluteThresholdDto
 */
export interface RHIMAPOReportingWearManagementApiV1ModelsAbsoluteThresholdDto {
    /**
     * 
     * @type {number}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsAbsoluteThresholdDto
     */
    'threshold': number;
    /**
     * 
     * @type {RHIMContractsMaintenanceTaskSeverity}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsAbsoluteThresholdDto
     */
    'severity': RHIMContractsMaintenanceTaskSeverity;
    /**
     * 
     * @type {string}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsAbsoluteThresholdDto
     */
    'maintenanceTask'?: string | null;
    /**
     * 
     * @type {RHIMContractsThresholdType}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsAbsoluteThresholdDto
     */
    'thresholdType': RHIMContractsThresholdType;
}


/**
 * 
 * @export
 * @interface RHIMAPOReportingWearManagementApiV1ModelsAbsoluteThresholdRequestDto
 */
export interface RHIMAPOReportingWearManagementApiV1ModelsAbsoluteThresholdRequestDto {
    /**
     * 
     * @type {number}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsAbsoluteThresholdRequestDto
     */
    'threshold': number;
    /**
     * 
     * @type {RHIMContractsMaintenanceTaskSeverity}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsAbsoluteThresholdRequestDto
     */
    'severity': RHIMContractsMaintenanceTaskSeverity;
    /**
     * 
     * @type {string}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsAbsoluteThresholdRequestDto
     */
    'maintenanceTask'?: string | null;
    /**
     * 
     * @type {RHIMContractsThresholdType}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsAbsoluteThresholdRequestDto
     */
    'thresholdType': RHIMContractsThresholdType;
}


/**
 * 
 * @export
 * @interface RHIMAPOReportingWearManagementApiV1ModelsBOFAccumulatedValue
 */
export interface RHIMAPOReportingWearManagementApiV1ModelsBOFAccumulatedValue {
    /**
     * 
     * @type {number}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsBOFAccumulatedValue
     */
    'x': number;
    /**
     * 
     * @type {number}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsBOFAccumulatedValue
     */
    'y': number;
}
/**
 * 
 * @export
 * @interface RHIMAPOReportingWearManagementApiV1ModelsBOFBofReportModelDto
 */
export interface RHIMAPOReportingWearManagementApiV1ModelsBOFBofReportModelDto {
    /**
     * 
     * @type {string}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsBOFBofReportModelDto
     */
    'campaignNr'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsBOFBofReportModelDto
     */
    'customer'?: string | null;
    /**
     * 
     * @type {RHIMAPOReportingWearManagementApiV1ModelsBOFLaserData}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsBOFBofReportModelDto
     */
    'laserData'?: RHIMAPOReportingWearManagementApiV1ModelsBOFLaserData | null;
    /**
     * 
     * @type {string}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsBOFBofReportModelDto
     */
    'predictionUTCTimestamp': string;
    /**
     * 
     * @type {RHIMAPOReportingWearManagementApiV1ModelsBOFProcessData}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsBOFBofReportModelDto
     */
    'processData'?: RHIMAPOReportingWearManagementApiV1ModelsBOFProcessData | null;
    /**
     * 
     * @type {RHIMAPOReportingWearManagementApiV1ModelsBOFReportData}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsBOFBofReportModelDto
     */
    'reportData'?: RHIMAPOReportingWearManagementApiV1ModelsBOFReportData | null;
    /**
     * 
     * @type {string}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsBOFBofReportModelDto
     */
    'schemaVersion'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsBOFBofReportModelDto
     */
    'vessel': number;
    /**
     * 
     * @type {string}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsBOFBofReportModelDto
     */
    'vesselId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsBOFBofReportModelDto
     */
    'vesselType'?: string | null;
}
/**
 * 
 * @export
 * @interface RHIMAPOReportingWearManagementApiV1ModelsBOFHotSpot
 */
export interface RHIMAPOReportingWearManagementApiV1ModelsBOFHotSpot {
    /**
     * 
     * @type {number}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsBOFHotSpot
     */
    'averageWearSpeed'?: number | null;
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsBOFHotSpot
     */
    'delta_kpis'?: { [key: string]: any; } | null;
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsBOFHotSpot
     */
    'heat_range_delta_kpis'?: { [key: string]: any; } | null;
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsBOFHotSpot
     */
    'heat_range_kpis'?: { [key: string]: any; } | null;
    /**
     * 
     * @type {string}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsBOFHotSpot
     */
    'id'?: string | null;
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsBOFHotSpot
     */
    'kpis'?: { [key: string]: any; } | null;
    /**
     * 
     * @type {Array<RHIMAPOReportingWearManagementApiV1ModelsBOFMeasuredPoint>}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsBOFHotSpot
     */
    'measuredPoints'?: Array<RHIMAPOReportingWearManagementApiV1ModelsBOFMeasuredPoint> | null;
    /**
     * 
     * @type {string}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsBOFHotSpot
     */
    'modelName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsBOFHotSpot
     */
    'name'?: string | null;
    /**
     * 
     * @type {Array<RHIMAPOReportingWearManagementApiV1ModelsBOFParameter>}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsBOFHotSpot
     */
    'parameters'?: Array<RHIMAPOReportingWearManagementApiV1ModelsBOFParameter> | null;
    /**
     * 
     * @type {number}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsBOFHotSpot
     */
    'predictedLifetime': number;
    /**
     * 
     * @type {Array<RHIMAPOReportingWearManagementApiV1ModelsBOFPredictedPoint>}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsBOFHotSpot
     */
    'predictedPoints'?: Array<RHIMAPOReportingWearManagementApiV1ModelsBOFPredictedPoint> | null;
    /**
     * 
     * @type {number}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsBOFHotSpot
     */
    'remainingHeats': number;
    /**
     * 
     * @type {RHIMAPOReportingWearManagementApiV1ModelsBOFStandardCampaign}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsBOFHotSpot
     */
    'standardCampaign'?: RHIMAPOReportingWearManagementApiV1ModelsBOFStandardCampaign | null;
    /**
     * 
     * @type {Array<RHIMAPOReportingWearManagementApiV1ModelsBOFTargetLifetimePoint>}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsBOFHotSpot
     */
    'targetLifetimePoints'?: Array<RHIMAPOReportingWearManagementApiV1ModelsBOFTargetLifetimePoint> | null;
    /**
     * 
     * @type {number}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsBOFHotSpot
     */
    'threshold': number;
    /**
     * 
     * @type {number}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsBOFHotSpot
     */
    'univariatePredictedLifetime'?: number | null;
    /**
     * 
     * @type {Array<RHIMAPOReportingWearManagementApiV1ModelsBOFUnivariatePredictedLifetimeOutlook>}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsBOFHotSpot
     */
    'univariatePredictedLifetimeOutlook'?: Array<RHIMAPOReportingWearManagementApiV1ModelsBOFUnivariatePredictedLifetimeOutlook> | null;
    /**
     * 
     * @type {Array<RHIMAPOReportingWearManagementApiV1ModelsBOFUnivariatePredictedPoint>}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsBOFHotSpot
     */
    'univariatePredictedPoints'?: Array<RHIMAPOReportingWearManagementApiV1ModelsBOFUnivariatePredictedPoint> | null;
    /**
     * 
     * @type {number}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsBOFHotSpot
     */
    'univariateRemainingHeats'?: number | null;
    /**
     * 
     * @type {RHIMAPOReportingWearManagementApiV1ModelsBOFWallplotDefinition}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsBOFHotSpot
     */
    'wallplotDefinition'?: RHIMAPOReportingWearManagementApiV1ModelsBOFWallplotDefinition | null;
    /**
     * 
     * @type {string}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsBOFHotSpot
     */
    'displayName'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsBOFHotSpot
     */
    'brickSuppliers'?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface RHIMAPOReportingWearManagementApiV1ModelsBOFLaserData
 */
export interface RHIMAPOReportingWearManagementApiV1ModelsBOFLaserData {
    /**
     * 
     * @type {number}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsBOFLaserData
     */
    'heatsSinceLastMeasurement'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsBOFLaserData
     */
    'lastHeat'?: number | null;
    /**
     * 
     * @type {Array<RHIMAPOReportingWearManagementApiV1ModelsBOFMetaDatum>}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsBOFLaserData
     */
    'metaData'?: Array<RHIMAPOReportingWearManagementApiV1ModelsBOFMetaDatum> | null;
}
/**
 * 
 * @export
 * @interface RHIMAPOReportingWearManagementApiV1ModelsBOFMeasuredPoint
 */
export interface RHIMAPOReportingWearManagementApiV1ModelsBOFMeasuredPoint {
    /**
     * 
     * @type {string}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsBOFMeasuredPoint
     */
    'type'?: string | null;
    /**
     * 
     * @type {Array<RHIMAPOReportingWearManagementApiV1ModelsBOFValue>}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsBOFMeasuredPoint
     */
    'values'?: Array<RHIMAPOReportingWearManagementApiV1ModelsBOFValue> | null;
}
/**
 * 
 * @export
 * @interface RHIMAPOReportingWearManagementApiV1ModelsBOFMetaDatum
 */
export interface RHIMAPOReportingWearManagementApiV1ModelsBOFMetaDatum {
    /**
     * 
     * @type {number}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsBOFMetaDatum
     */
    'heatNr': number;
    /**
     * 
     * @type {string}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsBOFMetaDatum
     */
    'heatUTCTimestamp': string;
}
/**
 * 
 * @export
 * @interface RHIMAPOReportingWearManagementApiV1ModelsBOFParameter
 */
export interface RHIMAPOReportingWearManagementApiV1ModelsBOFParameter {
    /**
     * 
     * @type {number}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsBOFParameter
     */
    'impact': number;
    /**
     * 
     * @type {string}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsBOFParameter
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsBOFParameter
     */
    'parameterName'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsBOFParameter
     */
    'isShortlisted': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsBOFParameter
     */
    'isComputed': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsBOFParameter
     */
    'hasModelImpact': boolean;
    /**
     * 
     * @type {string}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsBOFParameter
     */
    'unit'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsBOFParameter
     */
    'currentValue'?: number | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsBOFParameter
     */
    'historicValues'?: Array<number> | null;
    /**
     * 
     * @type {number}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsBOFParameter
     */
    'rangeMin'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsBOFParameter
     */
    'rangeMax'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsBOFParameter
     */
    'rangeInnerLimit'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsBOFParameter
     */
    'rangeOuterLimit'?: number | null;
}
/**
 * 
 * @export
 * @interface RHIMAPOReportingWearManagementApiV1ModelsBOFPoint
 */
export interface RHIMAPOReportingWearManagementApiV1ModelsBOFPoint {
    /**
     * 
     * @type {number}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsBOFPoint
     */
    'value'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsBOFPoint
     */
    'x': number;
    /**
     * 
     * @type {number}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsBOFPoint
     */
    'y': number;
}
/**
 * 
 * @export
 * @interface RHIMAPOReportingWearManagementApiV1ModelsBOFPredictedAccumulatedValue
 */
export interface RHIMAPOReportingWearManagementApiV1ModelsBOFPredictedAccumulatedValue {
    /**
     * 
     * @type {number}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsBOFPredictedAccumulatedValue
     */
    'x': number;
    /**
     * 
     * @type {number}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsBOFPredictedAccumulatedValue
     */
    'y': number;
}
/**
 * 
 * @export
 * @interface RHIMAPOReportingWearManagementApiV1ModelsBOFPredictedPoint
 */
export interface RHIMAPOReportingWearManagementApiV1ModelsBOFPredictedPoint {
    /**
     * 
     * @type {number}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsBOFPredictedPoint
     */
    'error'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsBOFPredictedPoint
     */
    'x': number;
    /**
     * 
     * @type {number}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsBOFPredictedPoint
     */
    'y': number;
}
/**
 * 
 * @export
 * @interface RHIMAPOReportingWearManagementApiV1ModelsBOFProcessData
 */
export interface RHIMAPOReportingWearManagementApiV1ModelsBOFProcessData {
    /**
     * 
     * @type {string}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsBOFProcessData
     */
    'firstHeatUTCTimestamp'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsBOFProcessData
     */
    'lastHeat': number;
    /**
     * 
     * @type {string}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsBOFProcessData
     */
    'lastHeatUTCTimestamp': string;
}
/**
 * 
 * @export
 * @interface RHIMAPOReportingWearManagementApiV1ModelsBOFProcessParameter
 */
export interface RHIMAPOReportingWearManagementApiV1ModelsBOFProcessParameter {
    /**
     * 
     * @type {Array<RHIMAPOReportingWearManagementApiV1ModelsBOFAccumulatedValue>}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsBOFProcessParameter
     */
    'accumulatedValues'?: Array<RHIMAPOReportingWearManagementApiV1ModelsBOFAccumulatedValue> | null;
    /**
     * 
     * @type {number}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsBOFProcessParameter
     */
    'forecastRate'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsBOFProcessParameter
     */
    'modelName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsBOFProcessParameter
     */
    'name'?: string | null;
    /**
     * 
     * @type {Array<RHIMAPOReportingWearManagementApiV1ModelsBOFPredictedAccumulatedValue>}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsBOFProcessParameter
     */
    'predictedAccumulatedValues'?: Array<RHIMAPOReportingWearManagementApiV1ModelsBOFPredictedAccumulatedValue> | null;
    /**
     * 
     * @type {number}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsBOFProcessParameter
     */
    'predictedLifetime': number;
    /**
     * 
     * @type {number}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsBOFProcessParameter
     */
    'remainingHeats': number;
    /**
     * 
     * @type {number}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsBOFProcessParameter
     */
    'threshold': number;
}
/**
 * 
 * @export
 * @interface RHIMAPOReportingWearManagementApiV1ModelsBOFReportData
 */
export interface RHIMAPOReportingWearManagementApiV1ModelsBOFReportData {
    /**
     * 
     * @type {Array<RHIMAPOReportingWearManagementApiV1ModelsBOFHotSpot>}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsBOFReportData
     */
    'hotSpots'?: Array<RHIMAPOReportingWearManagementApiV1ModelsBOFHotSpot> | null;
    /**
     * 
     * @type {Array<RHIMAPOReportingWearManagementApiV1ModelsBOFProcessParameter>}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsBOFReportData
     */
    'processParameters'?: Array<RHIMAPOReportingWearManagementApiV1ModelsBOFProcessParameter> | null;
    /**
     * 
     * @type {RHIMAPOReportingWearManagementApiV1ModelsBOFWallplot}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsBOFReportData
     */
    'wallplot'?: RHIMAPOReportingWearManagementApiV1ModelsBOFWallplot | null;
}
/**
 * 
 * @export
 * @interface RHIMAPOReportingWearManagementApiV1ModelsBOFStandardCampaign
 */
export interface RHIMAPOReportingWearManagementApiV1ModelsBOFStandardCampaign {
    /**
     * 
     * @type {Array<any>}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsBOFStandardCampaign
     */
    'lower_threshold'?: Array<any> | null;
    /**
     * 
     * @type {Array<any>}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsBOFStandardCampaign
     */
    'standard'?: Array<any> | null;
    /**
     * 
     * @type {Array<any>}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsBOFStandardCampaign
     */
    'upper_threshold'?: Array<any> | null;
}
/**
 * 
 * @export
 * @interface RHIMAPOReportingWearManagementApiV1ModelsBOFTargetLifetimePoint
 */
export interface RHIMAPOReportingWearManagementApiV1ModelsBOFTargetLifetimePoint {
    /**
     * 
     * @type {number}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsBOFTargetLifetimePoint
     */
    'x': number;
    /**
     * 
     * @type {number}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsBOFTargetLifetimePoint
     */
    'y': number;
}
/**
 * 
 * @export
 * @interface RHIMAPOReportingWearManagementApiV1ModelsBOFUnivariatePredictedLifetimeOutlook
 */
export interface RHIMAPOReportingWearManagementApiV1ModelsBOFUnivariatePredictedLifetimeOutlook {
    /**
     * 
     * @type {number}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsBOFUnivariatePredictedLifetimeOutlook
     */
    'x': number;
    /**
     * 
     * @type {number}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsBOFUnivariatePredictedLifetimeOutlook
     */
    'y': number;
}
/**
 * 
 * @export
 * @interface RHIMAPOReportingWearManagementApiV1ModelsBOFUnivariatePredictedPoint
 */
export interface RHIMAPOReportingWearManagementApiV1ModelsBOFUnivariatePredictedPoint {
    /**
     * 
     * @type {number}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsBOFUnivariatePredictedPoint
     */
    'x': number;
    /**
     * 
     * @type {number}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsBOFUnivariatePredictedPoint
     */
    'y': number;
}
/**
 * 
 * @export
 * @interface RHIMAPOReportingWearManagementApiV1ModelsBOFValue
 */
export interface RHIMAPOReportingWearManagementApiV1ModelsBOFValue {
    /**
     * 
     * @type {number}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsBOFValue
     */
    'x': number;
    /**
     * 
     * @type {number}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsBOFValue
     */
    'y': number;
}
/**
 * 
 * @export
 * @interface RHIMAPOReportingWearManagementApiV1ModelsBOFWallplot
 */
export interface RHIMAPOReportingWearManagementApiV1ModelsBOFWallplot {
    /**
     * 
     * @type {Array<RHIMAPOReportingWearManagementApiV1ModelsBOFPoint>}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsBOFWallplot
     */
    'points'?: Array<RHIMAPOReportingWearManagementApiV1ModelsBOFPoint> | null;
}
/**
 * 
 * @export
 * @interface RHIMAPOReportingWearManagementApiV1ModelsBOFWallplotDefinition
 */
export interface RHIMAPOReportingWearManagementApiV1ModelsBOFWallplotDefinition {
    /**
     * 
     * @type {number}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsBOFWallplotDefinition
     */
    'xMax': number;
    /**
     * 
     * @type {number}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsBOFWallplotDefinition
     */
    'xMin': number;
    /**
     * 
     * @type {number}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsBOFWallplotDefinition
     */
    'yMax': number;
    /**
     * 
     * @type {number}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsBOFWallplotDefinition
     */
    'yMin': number;
}
/**
 * 
 * @export
 * @interface RHIMAPOReportingWearManagementApiV1ModelsCRKCrkPredictionModelDto
 */
export interface RHIMAPOReportingWearManagementApiV1ModelsCRKCrkPredictionModelDto {
    /**
     * 
     * @type {string}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsCRKCrkPredictionModelDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsCRKCrkPredictionModelDto
     */
    'vesselId': string;
    /**
     * 
     * @type {number}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsCRKCrkPredictionModelDto
     */
    'campaign': number;
    /**
     * 
     * @type {string}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsCRKCrkPredictionModelDto
     */
    'schemaVersion'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsCRKCrkPredictionModelDto
     */
    'scoringTimestamp': string;
    /**
     * 
     * @type {Array<RHIMAPOReportingWearManagementApiV1ModelsCRKCrkPredictionModelZoneDto>}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsCRKCrkPredictionModelDto
     */
    'zones'?: Array<RHIMAPOReportingWearManagementApiV1ModelsCRKCrkPredictionModelZoneDto> | null;
}
/**
 * 
 * @export
 * @interface RHIMAPOReportingWearManagementApiV1ModelsCRKCrkPredictionModelHistoricDataPointDto
 */
export interface RHIMAPOReportingWearManagementApiV1ModelsCRKCrkPredictionModelHistoricDataPointDto {
    /**
     * 
     * @type {string}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsCRKCrkPredictionModelHistoricDataPointDto
     */
    'timeStamp': string;
    /**
     * 
     * @type {number}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsCRKCrkPredictionModelHistoricDataPointDto
     */
    'predictedValue'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsCRKCrkPredictionModelHistoricDataPointDto
     */
    'measuredValue'?: number | null;
}
/**
 * 
 * @export
 * @interface RHIMAPOReportingWearManagementApiV1ModelsCRKCrkPredictionModelPredictedDataPointDto
 */
export interface RHIMAPOReportingWearManagementApiV1ModelsCRKCrkPredictionModelPredictedDataPointDto {
    /**
     * 
     * @type {string}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsCRKCrkPredictionModelPredictedDataPointDto
     */
    'timeStamp': string;
    /**
     * 
     * @type {number}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsCRKCrkPredictionModelPredictedDataPointDto
     */
    'value'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsCRKCrkPredictionModelPredictedDataPointDto
     */
    'predictionConfidence'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsCRKCrkPredictionModelPredictedDataPointDto
     */
    'trend'?: number | null;
}
/**
 * 
 * @export
 * @interface RHIMAPOReportingWearManagementApiV1ModelsCRKCrkPredictionModelZoneDto
 */
export interface RHIMAPOReportingWearManagementApiV1ModelsCRKCrkPredictionModelZoneDto {
    /**
     * 
     * @type {string}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsCRKCrkPredictionModelZoneDto
     */
    'zoneId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsCRKCrkPredictionModelZoneDto
     */
    'zoneName'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsCRKCrkPredictionModelZoneDto
     */
    'startingRunningMeter': number;
    /**
     * 
     * @type {number}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsCRKCrkPredictionModelZoneDto
     */
    'endingRunningMeter': number;
    /**
     * 
     * @type {number}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsCRKCrkPredictionModelZoneDto
     */
    'minTemperature': number;
    /**
     * 
     * @type {number}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsCRKCrkPredictionModelZoneDto
     */
    'maxTemperature': number;
    /**
     * 
     * @type {number}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsCRKCrkPredictionModelZoneDto
     */
    'thresholdTemperature'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsCRKCrkPredictionModelZoneDto
     */
    'predictionWindow': number;
    /**
     * 
     * @type {Array<RHIMAPOReportingWearManagementApiV1ModelsCRKCrkPredictionModelPredictedDataPointDto>}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsCRKCrkPredictionModelZoneDto
     */
    'predictions'?: Array<RHIMAPOReportingWearManagementApiV1ModelsCRKCrkPredictionModelPredictedDataPointDto> | null;
    /**
     * 
     * @type {number}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsCRKCrkPredictionModelZoneDto
     */
    'historicPredictionWindow': number;
    /**
     * 
     * @type {number}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsCRKCrkPredictionModelZoneDto
     */
    'historicRMSE'?: number | null;
    /**
     * 
     * @type {Array<RHIMAPOReportingWearManagementApiV1ModelsCRKCrkPredictionModelHistoricDataPointDto>}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsCRKCrkPredictionModelZoneDto
     */
    'historicData'?: Array<RHIMAPOReportingWearManagementApiV1ModelsCRKCrkPredictionModelHistoricDataPointDto> | null;
}
/**
 * 
 * @export
 * @interface RHIMAPOReportingWearManagementApiV1ModelsCampaignOverviewDto
 */
export interface RHIMAPOReportingWearManagementApiV1ModelsCampaignOverviewDto {
    /**
     * The Id of a campaign. A value of null indicates that there are other entities that are not assigned to a campaign.
     * @type {number}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsCampaignOverviewDto
     */
    'id'?: number | null;
    /**
     * The relation to a Vessel.
     * @type {string}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsCampaignOverviewDto
     */
    'vesselId': string;
    /**
     * The text to be displayed for a campaign.
     * @type {string}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsCampaignOverviewDto
     */
    'displayText': string;
}
/**
 * 
 * @export
 * @interface RHIMAPOReportingWearManagementApiV1ModelsCircleDto
 */
export interface RHIMAPOReportingWearManagementApiV1ModelsCircleDto {
    /**
     * The radius of the circle
     * @type {number}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsCircleDto
     */
    'circleRadius': number;
    /**
     * 
     * @type {RHIMAPOCommonDataReportingRegionPoint}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsCircleDto
     */
    'midPoint': RHIMAPOCommonDataReportingRegionPoint;
}
/**
 * 
 * @export
 * @interface RHIMAPOReportingWearManagementApiV1ModelsColorscaleDto
 */
export interface RHIMAPOReportingWearManagementApiV1ModelsColorscaleDto {
    /**
     * The type of vessel for this colorscale
     * @type {string}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsColorscaleDto
     */
    'vesselType': string;
    /**
     * 
     * @type {RHIMAPOCommonDataReportingColorScaleType}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsColorscaleDto
     */
    'colorScaleType'?: RHIMAPOCommonDataReportingColorScaleType | null;
    /**
     * A list of thresholds and colors
     * @type {Array<RHIMAPOReportingWearManagementApiV1ModelsThresholdAndColorDto>}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsColorscaleDto
     */
    'thresholdsAndColors': Array<RHIMAPOReportingWearManagementApiV1ModelsThresholdAndColorDto>;
}


/**
 * 
 * @export
 * @interface RHIMAPOReportingWearManagementApiV1ModelsCreateOrUpdateCrkZoneDto
 */
export interface RHIMAPOReportingWearManagementApiV1ModelsCreateOrUpdateCrkZoneDto {
    /**
     * 
     * @type {string}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsCreateOrUpdateCrkZoneDto
     */
    'label': string;
    /**
     * 
     * @type {number}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsCreateOrUpdateCrkZoneDto
     */
    'rmFrom': number;
    /**
     * 
     * @type {number}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsCreateOrUpdateCrkZoneDto
     */
    'rmTo': number;
}
/**
 * 
 * @export
 * @interface RHIMAPOReportingWearManagementApiV1ModelsCreateOrUpdateCrkZoneSetDto
 */
export interface RHIMAPOReportingWearManagementApiV1ModelsCreateOrUpdateCrkZoneSetDto {
    /**
     * 
     * @type {string}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsCreateOrUpdateCrkZoneSetDto
     */
    'displayName': string;
    /**
     * 
     * @type {string}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsCreateOrUpdateCrkZoneSetDto
     */
    'startDate': string;
    /**
     * 
     * @type {boolean}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsCreateOrUpdateCrkZoneSetDto
     */
    'isEnabled': boolean;
    /**
     * 
     * @type {Array<RHIMAPOReportingWearManagementApiV1ModelsCreateOrUpdateCrkZoneDto>}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsCreateOrUpdateCrkZoneSetDto
     */
    'crkZones'?: Array<RHIMAPOReportingWearManagementApiV1ModelsCreateOrUpdateCrkZoneDto> | null;
}
/**
 * 
 * @export
 * @interface RHIMAPOReportingWearManagementApiV1ModelsCrkCylinderDto
 */
export interface RHIMAPOReportingWearManagementApiV1ModelsCrkCylinderDto {
    /**
     * The running meter value of the kiln ring
     * @type {number}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsCrkCylinderDto
     */
    'runningMeter': number;
    /**
     * The width in cm of the kiln ring
     * @type {number}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsCrkCylinderDto
     */
    'width': number;
}
/**
 * 
 * @export
 * @interface RHIMAPOReportingWearManagementApiV1ModelsCrkZoneDto
 */
export interface RHIMAPOReportingWearManagementApiV1ModelsCrkZoneDto {
    /**
     * 
     * @type {string}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsCrkZoneDto
     */
    'label'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsCrkZoneDto
     */
    'rmFrom': number;
    /**
     * 
     * @type {number}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsCrkZoneDto
     */
    'rmTo': number;
}
/**
 * 
 * @export
 * @interface RHIMAPOReportingWearManagementApiV1ModelsCrkZoneSetDto
 */
export interface RHIMAPOReportingWearManagementApiV1ModelsCrkZoneSetDto {
    /**
     * 
     * @type {string}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsCrkZoneSetDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsCrkZoneSetDto
     */
    'displayName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsCrkZoneSetDto
     */
    'startDate': string;
    /**
     * 
     * @type {boolean}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsCrkZoneSetDto
     */
    'isEnabled': boolean;
    /**
     * 
     * @type {Array<RHIMAPOReportingWearManagementApiV1ModelsCrkZoneDto>}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsCrkZoneSetDto
     */
    'crkZones'?: Array<RHIMAPOReportingWearManagementApiV1ModelsCrkZoneDto> | null;
}
/**
 * 
 * @export
 * @interface RHIMAPOReportingWearManagementApiV1ModelsDataScienceModelSelectionDto
 */
export interface RHIMAPOReportingWearManagementApiV1ModelsDataScienceModelSelectionDto {
    /**
     * The vessel type for which this model selection is used
     * @type {string}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsDataScienceModelSelectionDto
     */
    'vesselType': string;
    /**
     * The id of the customer whose data science model is to be used.  Can be null if HostingMode is Disabled as no data science model is needed then.
     * @type {string}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsDataScienceModelSelectionDto
     */
    'targetCustomerId'?: string | null;
    /**
     * 
     * @type {RHIMAPOCommonDataReportingHostingMode}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsDataScienceModelSelectionDto
     */
    'hostingMode': RHIMAPOCommonDataReportingHostingMode;
}


/**
 * 
 * @export
 * @interface RHIMAPOReportingWearManagementApiV1ModelsOrientationLabelDto
 */
export interface RHIMAPOReportingWearManagementApiV1ModelsOrientationLabelDto {
    /**
     * The angle of the orientation label in degrees
     * @type {number}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsOrientationLabelDto
     */
    'angle': number;
    /**
     * The display text of the orientation label
     * @type {string}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsOrientationLabelDto
     */
    'displayText'?: string | null;
}
/**
 * 
 * @export
 * @interface RHIMAPOReportingWearManagementApiV1ModelsOrientationLabelUpdateDto
 */
export interface RHIMAPOReportingWearManagementApiV1ModelsOrientationLabelUpdateDto {
    /**
     * The type of vessel for this orientation label
     * @type {string}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsOrientationLabelUpdateDto
     */
    'vesselType': string;
    /**
     * A list of thresholds and colors
     * @type {Array<RHIMAPOReportingWearManagementApiV1ModelsOrientationLabelDto>}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsOrientationLabelUpdateDto
     */
    'angleAndDescription': Array<RHIMAPOReportingWearManagementApiV1ModelsOrientationLabelDto>;
}
/**
 * 
 * @export
 * @interface RHIMAPOReportingWearManagementApiV1ModelsPredictionOverviewDto
 */
export interface RHIMAPOReportingWearManagementApiV1ModelsPredictionOverviewDto {
    /**
     * The Id of the prediction
     * @type {string}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsPredictionOverviewDto
     */
    'id': string;
    /**
     * The Id of the vessel to which this prediction is assigned.
     * @type {string}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsPredictionOverviewDto
     */
    'vesselId': string;
    /**
     * The Id of the campaign.
     * @type {number}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsPredictionOverviewDto
     */
    'campaign': number;
    /**
     * The date, time and timezone of the prediction. Could be empty.
     * @type {string}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsPredictionOverviewDto
     */
    'scoringTimestamp': string;
}
/**
 * 
 * @export
 * @interface RHIMAPOReportingWearManagementApiV1ModelsRHAccumulatedValue
 */
export interface RHIMAPOReportingWearManagementApiV1ModelsRHAccumulatedValue {
    /**
     * 
     * @type {number}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsRHAccumulatedValue
     */
    'x': number;
    /**
     * 
     * @type {number}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsRHAccumulatedValue
     */
    'y': number;
}
/**
 * 
 * @export
 * @interface RHIMAPOReportingWearManagementApiV1ModelsRHBoxParameter
 */
export interface RHIMAPOReportingWearManagementApiV1ModelsRHBoxParameter {
    /**
     * 
     * @type {string}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsRHBoxParameter
     */
    'name'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsRHBoxParameter
     */
    'value': number;
}
/**
 * 
 * @export
 * @interface RHIMAPOReportingWearManagementApiV1ModelsRHCustomArea
 */
export interface RHIMAPOReportingWearManagementApiV1ModelsRHCustomArea {
    /**
     * 
     * @type {Array<RHIMAPOReportingWearManagementApiV1ModelsRHBoxParameter>}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsRHCustomArea
     */
    'box_parameters'?: Array<RHIMAPOReportingWearManagementApiV1ModelsRHBoxParameter> | null;
    /**
     * 
     * @type {number}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsRHCustomArea
     */
    'lower_vessel_number': number;
    /**
     * 
     * @type {string}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsRHCustomArea
     */
    'start_date': string;
}
/**
 * 
 * @export
 * @interface RHIMAPOReportingWearManagementApiV1ModelsRHHotSpot
 */
export interface RHIMAPOReportingWearManagementApiV1ModelsRHHotSpot {
    /**
     * 
     * @type {number}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsRHHotSpot
     */
    'forecastRate'?: number | null;
    /**
     * 
     * @type {Array<RHIMAPOReportingWearManagementApiV1ModelsRHMeasuredPoint>}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsRHHotSpot
     */
    'measuredPoints'?: Array<RHIMAPOReportingWearManagementApiV1ModelsRHMeasuredPoint> | null;
    /**
     * 
     * @type {string}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsRHHotSpot
     */
    'modelName'?: string | null;
    /**
     * 
     * @type {Array<any>}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsRHHotSpot
     */
    'modelPredictions'?: Array<any> | null;
    /**
     * 
     * @type {string}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsRHHotSpot
     */
    'name'?: string | null;
    /**
     * 
     * @type {Array<RHIMAPOReportingWearManagementApiV1ModelsRHParameter>}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsRHHotSpot
     */
    'parameters'?: Array<RHIMAPOReportingWearManagementApiV1ModelsRHParameter> | null;
    /**
     * 
     * @type {number}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsRHHotSpot
     */
    'predictedLifetime': number;
    /**
     * 
     * @type {Array<RHIMAPOReportingWearManagementApiV1ModelsRHPredictedPoint>}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsRHHotSpot
     */
    'predictedPoints'?: Array<RHIMAPOReportingWearManagementApiV1ModelsRHPredictedPoint> | null;
    /**
     * 
     * @type {number}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsRHHotSpot
     */
    'remainingHeats': number;
    /**
     * 
     * @type {RHIMAPOReportingWearManagementApiV1ModelsRHStandardCampaign}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsRHHotSpot
     */
    'standardCampaign'?: RHIMAPOReportingWearManagementApiV1ModelsRHStandardCampaign | null;
    /**
     * 
     * @type {Array<RHIMAPOReportingWearManagementApiV1ModelsRHTargetLifetimePoint>}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsRHHotSpot
     */
    'targetLifetimePoints'?: Array<RHIMAPOReportingWearManagementApiV1ModelsRHTargetLifetimePoint> | null;
    /**
     * 
     * @type {number}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsRHHotSpot
     */
    'threshold': number;
    /**
     * 
     * @type {number}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsRHHotSpot
     */
    'univariatePredictedLifetime'?: number | null;
    /**
     * 
     * @type {Array<RHIMAPOReportingWearManagementApiV1ModelsRHUnivariatePredictedLifetimeOutlook>}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsRHHotSpot
     */
    'univariatePredictedLifetimeOutlook'?: Array<RHIMAPOReportingWearManagementApiV1ModelsRHUnivariatePredictedLifetimeOutlook> | null;
    /**
     * 
     * @type {Array<RHIMAPOReportingWearManagementApiV1ModelsRHUnivariatePredictedPoint>}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsRHHotSpot
     */
    'univariatePredictedPoints'?: Array<RHIMAPOReportingWearManagementApiV1ModelsRHUnivariatePredictedPoint> | null;
    /**
     * 
     * @type {number}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsRHHotSpot
     */
    'univariateRemainingHeats'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsRHHotSpot
     */
    'displayName'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsRHHotSpot
     */
    'brickSuppliers'?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface RHIMAPOReportingWearManagementApiV1ModelsRHLaserData
 */
export interface RHIMAPOReportingWearManagementApiV1ModelsRHLaserData {
    /**
     * 
     * @type {Array<RHIMAPOReportingWearManagementApiV1ModelsRHMetaDatum>}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsRHLaserData
     */
    'metaData'?: Array<RHIMAPOReportingWearManagementApiV1ModelsRHMetaDatum> | null;
}
/**
 * 
 * @export
 * @interface RHIMAPOReportingWearManagementApiV1ModelsRHMeasuredPoint
 */
export interface RHIMAPOReportingWearManagementApiV1ModelsRHMeasuredPoint {
    /**
     * 
     * @type {string}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsRHMeasuredPoint
     */
    'type'?: string | null;
    /**
     * 
     * @type {Array<RHIMAPOReportingWearManagementApiV1ModelsRHValue>}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsRHMeasuredPoint
     */
    'values'?: Array<RHIMAPOReportingWearManagementApiV1ModelsRHValue> | null;
}
/**
 * 
 * @export
 * @interface RHIMAPOReportingWearManagementApiV1ModelsRHMetaDatum
 */
export interface RHIMAPOReportingWearManagementApiV1ModelsRHMetaDatum {
    /**
     * 
     * @type {number}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsRHMetaDatum
     */
    'heatNr': number;
    /**
     * 
     * @type {string}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsRHMetaDatum
     */
    'heatUTCTimestamp'?: string | null;
}
/**
 * 
 * @export
 * @interface RHIMAPOReportingWearManagementApiV1ModelsRHParameter
 */
export interface RHIMAPOReportingWearManagementApiV1ModelsRHParameter {
    /**
     * 
     * @type {number}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsRHParameter
     */
    'impact': number;
    /**
     * 
     * @type {string}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsRHParameter
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsRHParameter
     */
    'parameterName'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsRHParameter
     */
    'isShortlisted': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsRHParameter
     */
    'isComputed': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsRHParameter
     */
    'hasModelImpact': boolean;
    /**
     * 
     * @type {string}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsRHParameter
     */
    'unit'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsRHParameter
     */
    'currentValue'?: number | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsRHParameter
     */
    'historicValues'?: Array<number> | null;
    /**
     * 
     * @type {number}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsRHParameter
     */
    'rangeMin'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsRHParameter
     */
    'rangeMax'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsRHParameter
     */
    'rangeInnerLimit'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsRHParameter
     */
    'rangeOuterLimit'?: number | null;
}
/**
 * 
 * @export
 * @interface RHIMAPOReportingWearManagementApiV1ModelsRHPredictedAccumulatedValue
 */
export interface RHIMAPOReportingWearManagementApiV1ModelsRHPredictedAccumulatedValue {
    /**
     * 
     * @type {number}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsRHPredictedAccumulatedValue
     */
    'x': number;
    /**
     * 
     * @type {number}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsRHPredictedAccumulatedValue
     */
    'y': number;
}
/**
 * 
 * @export
 * @interface RHIMAPOReportingWearManagementApiV1ModelsRHPredictedPoint
 */
export interface RHIMAPOReportingWearManagementApiV1ModelsRHPredictedPoint {
    /**
     * 
     * @type {number}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsRHPredictedPoint
     */
    'error'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsRHPredictedPoint
     */
    'x': number;
    /**
     * 
     * @type {number}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsRHPredictedPoint
     */
    'y': number;
}
/**
 * 
 * @export
 * @interface RHIMAPOReportingWearManagementApiV1ModelsRHProcessData
 */
export interface RHIMAPOReportingWearManagementApiV1ModelsRHProcessData {
    /**
     * 
     * @type {string}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsRHProcessData
     */
    'firstHeatUTCTimestamp'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsRHProcessData
     */
    'lastHeat': number;
    /**
     * 
     * @type {string}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsRHProcessData
     */
    'lastHeatUTCTimestamp': string;
}
/**
 * 
 * @export
 * @interface RHIMAPOReportingWearManagementApiV1ModelsRHProcessParameter
 */
export interface RHIMAPOReportingWearManagementApiV1ModelsRHProcessParameter {
    /**
     * 
     * @type {Array<RHIMAPOReportingWearManagementApiV1ModelsRHAccumulatedValue>}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsRHProcessParameter
     */
    'accumulatedValues'?: Array<RHIMAPOReportingWearManagementApiV1ModelsRHAccumulatedValue> | null;
    /**
     * 
     * @type {number}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsRHProcessParameter
     */
    'forecastRate'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsRHProcessParameter
     */
    'modelName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsRHProcessParameter
     */
    'name'?: string | null;
    /**
     * 
     * @type {Array<RHIMAPOReportingWearManagementApiV1ModelsRHPredictedAccumulatedValue>}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsRHProcessParameter
     */
    'predictedAccumulatedValues'?: Array<RHIMAPOReportingWearManagementApiV1ModelsRHPredictedAccumulatedValue> | null;
    /**
     * 
     * @type {number}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsRHProcessParameter
     */
    'predictedLifetime': number;
    /**
     * 
     * @type {number}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsRHProcessParameter
     */
    'remainingHeats': number;
    /**
     * 
     * @type {number}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsRHProcessParameter
     */
    'threshold': number;
}
/**
 * 
 * @export
 * @interface RHIMAPOReportingWearManagementApiV1ModelsRHReportData
 */
export interface RHIMAPOReportingWearManagementApiV1ModelsRHReportData {
    /**
     * 
     * @type {RHIMAPOReportingWearManagementApiV1ModelsRHReportDataCustomArea}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsRHReportData
     */
    'custom_area'?: RHIMAPOReportingWearManagementApiV1ModelsRHReportDataCustomArea | null;
    /**
     * 
     * @type {Array<RHIMAPOReportingWearManagementApiV1ModelsRHHotSpot>}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsRHReportData
     */
    'hotSpots'?: Array<RHIMAPOReportingWearManagementApiV1ModelsRHHotSpot> | null;
    /**
     * 
     * @type {Array<RHIMAPOReportingWearManagementApiV1ModelsRHHotSpot>}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsRHReportData
     */
    'hotSpotsDiameter'?: Array<RHIMAPOReportingWearManagementApiV1ModelsRHHotSpot> | null;
    /**
     * 
     * @type {Array<RHIMAPOReportingWearManagementApiV1ModelsRHProcessParameter>}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsRHReportData
     */
    'processParameters'?: Array<RHIMAPOReportingWearManagementApiV1ModelsRHProcessParameter> | null;
}
/**
 * DANGER!! Keep this! DS is maintaining in two repositories the schemas. One is customer specific and one generic. If the schema is regenerated this part needs to stay
 * @export
 * @interface RHIMAPOReportingWearManagementApiV1ModelsRHReportDataCustomArea
 */
export interface RHIMAPOReportingWearManagementApiV1ModelsRHReportDataCustomArea {
    /**
     * 
     * @type {Array<RHIMAPOReportingWearManagementApiV1ModelsRHBoxParameter>}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsRHReportDataCustomArea
     */
    'box_parameters'?: Array<RHIMAPOReportingWearManagementApiV1ModelsRHBoxParameter> | null;
    /**
     * 
     * @type {number}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsRHReportDataCustomArea
     */
    'lower_vessel_number': number;
    /**
     * 
     * @type {string}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsRHReportDataCustomArea
     */
    'start_date': string;
}
/**
 * 
 * @export
 * @interface RHIMAPOReportingWearManagementApiV1ModelsRHRhReportModelDto
 */
export interface RHIMAPOReportingWearManagementApiV1ModelsRHRhReportModelDto {
    /**
     * 
     * @type {number}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsRHRhReportModelDto
     */
    'campaignNr': number;
    /**
     * 
     * @type {string}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsRHRhReportModelDto
     */
    'customer'?: string | null;
    /**
     * 
     * @type {RHIMAPOReportingWearManagementApiV1ModelsRHLaserData}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsRHRhReportModelDto
     */
    'laserData'?: RHIMAPOReportingWearManagementApiV1ModelsRHLaserData | null;
    /**
     * 
     * @type {string}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsRHRhReportModelDto
     */
    'predictionUTCTimestamp': string;
    /**
     * 
     * @type {RHIMAPOReportingWearManagementApiV1ModelsRHProcessData}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsRHRhReportModelDto
     */
    'processData'?: RHIMAPOReportingWearManagementApiV1ModelsRHProcessData | null;
    /**
     * 
     * @type {RHIMAPOReportingWearManagementApiV1ModelsRHReportData}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsRHRhReportModelDto
     */
    'reportData'?: RHIMAPOReportingWearManagementApiV1ModelsRHReportData | null;
    /**
     * 
     * @type {string}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsRHRhReportModelDto
     */
    'schemaVersion'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsRHRhReportModelDto
     */
    'vessel': number;
    /**
     * 
     * @type {string}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsRHRhReportModelDto
     */
    'vesselId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsRHRhReportModelDto
     */
    'vesselType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsRHRhReportModelDto
     */
    'campaignDisplayText'?: string | null;
}
/**
 * 
 * @export
 * @interface RHIMAPOReportingWearManagementApiV1ModelsRHStandardCampaign
 */
export interface RHIMAPOReportingWearManagementApiV1ModelsRHStandardCampaign {
    /**
     * 
     * @type {Array<any>}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsRHStandardCampaign
     */
    'lower_threshold'?: Array<any> | null;
    /**
     * 
     * @type {Array<any>}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsRHStandardCampaign
     */
    'standard'?: Array<any> | null;
    /**
     * 
     * @type {Array<any>}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsRHStandardCampaign
     */
    'upper_threshold'?: Array<any> | null;
}
/**
 * 
 * @export
 * @interface RHIMAPOReportingWearManagementApiV1ModelsRHTargetLifetimePoint
 */
export interface RHIMAPOReportingWearManagementApiV1ModelsRHTargetLifetimePoint {
    /**
     * 
     * @type {number}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsRHTargetLifetimePoint
     */
    'x': number;
    /**
     * 
     * @type {number}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsRHTargetLifetimePoint
     */
    'y': number;
}
/**
 * 
 * @export
 * @interface RHIMAPOReportingWearManagementApiV1ModelsRHUnivariatePredictedLifetimeOutlook
 */
export interface RHIMAPOReportingWearManagementApiV1ModelsRHUnivariatePredictedLifetimeOutlook {
    /**
     * 
     * @type {number}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsRHUnivariatePredictedLifetimeOutlook
     */
    'x': number;
    /**
     * 
     * @type {number}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsRHUnivariatePredictedLifetimeOutlook
     */
    'y': number;
}
/**
 * 
 * @export
 * @interface RHIMAPOReportingWearManagementApiV1ModelsRHUnivariatePredictedPoint
 */
export interface RHIMAPOReportingWearManagementApiV1ModelsRHUnivariatePredictedPoint {
    /**
     * 
     * @type {number}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsRHUnivariatePredictedPoint
     */
    'x': number;
    /**
     * 
     * @type {number}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsRHUnivariatePredictedPoint
     */
    'y': number;
}
/**
 * 
 * @export
 * @interface RHIMAPOReportingWearManagementApiV1ModelsRHValue
 */
export interface RHIMAPOReportingWearManagementApiV1ModelsRHValue {
    /**
     * 
     * @type {number}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsRHValue
     */
    'x': number;
    /**
     * 
     * @type {number}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsRHValue
     */
    'y': number;
}
/**
 * see RHIM.APO.Common.Data.Reporting.Region
 * @export
 * @interface RHIMAPOReportingWearManagementApiV1ModelsRegionCreateRequestDto
 */
export interface RHIMAPOReportingWearManagementApiV1ModelsRegionCreateRequestDto {
    /**
     * 
     * @type {string}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsRegionCreateRequestDto
     */
    'vesselId': string;
    /**
     * 
     * @type {number}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsRegionCreateRequestDto
     * @deprecated
     */
    'order': number;
    /**
     * 
     * @type {string}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsRegionCreateRequestDto
     * @deprecated
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsRegionCreateRequestDto
     */
    'displayName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsRegionCreateRequestDto
     */
    'shortName'?: string | null;
    /**
     * 
     * @type {RHIMContractsRegionLocation}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsRegionCreateRequestDto
     */
    'regionLocation': RHIMContractsRegionLocation;
    /**
     * 
     * @type {Array<RHIMAPOCommonDataReportingRegionTag>}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsRegionCreateRequestDto
     */
    'tags': Array<RHIMAPOCommonDataReportingRegionTag>;
    /**
     * 
     * @type {RHIMAPOCommonDataReportingCoordinateSystem2D}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsRegionCreateRequestDto
     */
    'coordinateSystem': RHIMAPOCommonDataReportingCoordinateSystem2D;
    /**
     * 
     * @type {Array<RHIMAPOCommonDataReportingRegionPoint>}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsRegionCreateRequestDto
     */
    'area': Array<RHIMAPOCommonDataReportingRegionPoint>;
    /**
     * 
     * @type {Array<RHIMAPOReportingWearManagementApiV1ModelsThresholdWearCurveRequestDto>}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsRegionCreateRequestDto
     */
    'thresholdWearCurves'?: Array<RHIMAPOReportingWearManagementApiV1ModelsThresholdWearCurveRequestDto> | null;
    /**
     * 
     * @type {Array<RHIMAPOReportingWearManagementApiV1ModelsAbsoluteThresholdRequestDto>}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsRegionCreateRequestDto
     */
    'absoluteThresholds'?: Array<RHIMAPOReportingWearManagementApiV1ModelsAbsoluteThresholdRequestDto> | null;
}


/**
 * see RHIM.APO.Common.Data.Reporting.Region
 * @export
 * @interface RHIMAPOReportingWearManagementApiV1ModelsRegionDto
 */
export interface RHIMAPOReportingWearManagementApiV1ModelsRegionDto {
    /**
     * 
     * @type {string}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsRegionDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsRegionDto
     */
    'vesselId': string;
    /**
     * 
     * @type {number}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsRegionDto
     * @deprecated
     */
    'order': number;
    /**
     * 
     * @type {string}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsRegionDto
     * @deprecated
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsRegionDto
     */
    'displayName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsRegionDto
     */
    'shortName'?: string | null;
    /**
     * 
     * @type {RHIMContractsRegionLocation}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsRegionDto
     */
    'regionLocation': RHIMContractsRegionLocation;
    /**
     * 
     * @type {Array<RHIMAPOCommonDataReportingRegionTag>}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsRegionDto
     */
    'tags'?: Array<RHIMAPOCommonDataReportingRegionTag> | null;
    /**
     * 
     * @type {RHIMAPOCommonDataReportingCoordinateSystem2D}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsRegionDto
     */
    'coordinateSystem': RHIMAPOCommonDataReportingCoordinateSystem2D;
    /**
     * 
     * @type {Array<RHIMAPOCommonDataReportingRegionPoint>}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsRegionDto
     */
    'area'?: Array<RHIMAPOCommonDataReportingRegionPoint> | null;
    /**
     * 
     * @type {Array<RHIMAPOReportingWearManagementApiV1ModelsThresholdWearCurveDto>}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsRegionDto
     */
    'thresholdWearCurves'?: Array<RHIMAPOReportingWearManagementApiV1ModelsThresholdWearCurveDto> | null;
    /**
     * 
     * @type {Array<RHIMAPOReportingWearManagementApiV1ModelsAbsoluteThresholdDto>}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsRegionDto
     */
    'absoluteThresholds'?: Array<RHIMAPOReportingWearManagementApiV1ModelsAbsoluteThresholdDto> | null;
}


/**
 * see RHIM.APO.Common.Data.Reporting.Region
 * @export
 * @interface RHIMAPOReportingWearManagementApiV1ModelsRegionUpdateRequestDto
 */
export interface RHIMAPOReportingWearManagementApiV1ModelsRegionUpdateRequestDto {
    /**
     * 
     * @type {number}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsRegionUpdateRequestDto
     * @deprecated
     */
    'order': number;
    /**
     * 
     * @type {string}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsRegionUpdateRequestDto
     * @deprecated
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsRegionUpdateRequestDto
     */
    'displayName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsRegionUpdateRequestDto
     */
    'shortName'?: string | null;
    /**
     * 
     * @type {RHIMContractsRegionLocation}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsRegionUpdateRequestDto
     */
    'regionLocation': RHIMContractsRegionLocation;
    /**
     * 
     * @type {Array<RHIMAPOCommonDataReportingRegionTag>}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsRegionUpdateRequestDto
     */
    'tags': Array<RHIMAPOCommonDataReportingRegionTag>;
    /**
     * 
     * @type {RHIMAPOCommonDataReportingCoordinateSystem2D}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsRegionUpdateRequestDto
     */
    'coordinateSystem': RHIMAPOCommonDataReportingCoordinateSystem2D;
    /**
     * 
     * @type {Array<RHIMAPOCommonDataReportingRegionPoint>}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsRegionUpdateRequestDto
     */
    'area': Array<RHIMAPOCommonDataReportingRegionPoint>;
    /**
     * 
     * @type {Array<RHIMAPOReportingWearManagementApiV1ModelsThresholdWearCurveRequestDto>}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsRegionUpdateRequestDto
     */
    'thresholdWearCurves'?: Array<RHIMAPOReportingWearManagementApiV1ModelsThresholdWearCurveRequestDto> | null;
    /**
     * 
     * @type {Array<RHIMAPOReportingWearManagementApiV1ModelsAbsoluteThresholdRequestDto>}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsRegionUpdateRequestDto
     */
    'absoluteThresholds'?: Array<RHIMAPOReportingWearManagementApiV1ModelsAbsoluteThresholdRequestDto> | null;
}


/**
 * 
 * @export
 * @interface RHIMAPOReportingWearManagementApiV1ModelsThresholdAndColorDto
 */
export interface RHIMAPOReportingWearManagementApiV1ModelsThresholdAndColorDto {
    /**
     * The threshold (normally in mm) when the configured color should be used.
     * @type {number}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsThresholdAndColorDto
     */
    'threshold': number;
    /**
     * The color for the threshold in hex color codes  Example: #ff9933
     * @type {string}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsThresholdAndColorDto
     */
    'color': string;
}
/**
 * see RHIM.APO.Common.Data.Reporting.ThresholdWearCurve
 * @export
 * @interface RHIMAPOReportingWearManagementApiV1ModelsThresholdWearCurveDto
 */
export interface RHIMAPOReportingWearManagementApiV1ModelsThresholdWearCurveDto {
    /**
     * 
     * @type {number}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsThresholdWearCurveDto
     */
    'relativeInitialLiningThickness': number;
    /**
     * 
     * @type {number}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsThresholdWearCurveDto
     */
    'relativeLiningThicknessAtExpectedLifetime': number;
    /**
     * 
     * @type {number}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsThresholdWearCurveDto
     */
    'curveBendingFactor': number;
    /**
     * 
     * @type {RHIMContractsMaintenanceTaskSeverity}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsThresholdWearCurveDto
     */
    'severity': RHIMContractsMaintenanceTaskSeverity;
    /**
     * 
     * @type {string}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsThresholdWearCurveDto
     */
    'maintenanceTask'?: string | null;
    /**
     * 
     * @type {RHIMContractsThresholdType}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsThresholdWearCurveDto
     */
    'wearCurveType': RHIMContractsThresholdType;
}


/**
 * see RHIM.APO.Common.Data.Reporting.ThresholdWearCurve
 * @export
 * @interface RHIMAPOReportingWearManagementApiV1ModelsThresholdWearCurveRequestDto
 */
export interface RHIMAPOReportingWearManagementApiV1ModelsThresholdWearCurveRequestDto {
    /**
     * 
     * @type {number}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsThresholdWearCurveRequestDto
     */
    'relativeInitialLiningThickness': number;
    /**
     * 
     * @type {number}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsThresholdWearCurveRequestDto
     */
    'relativeLiningThicknessAtExpectedLifetime': number;
    /**
     * 
     * @type {number}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsThresholdWearCurveRequestDto
     */
    'curveBendingFactor': number;
    /**
     * 
     * @type {RHIMContractsMaintenanceTaskSeverity}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsThresholdWearCurveRequestDto
     */
    'severity': RHIMContractsMaintenanceTaskSeverity;
    /**
     * 
     * @type {string}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsThresholdWearCurveRequestDto
     */
    'maintenanceTask'?: string | null;
    /**
     * 
     * @type {RHIMContractsThresholdType}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsThresholdWearCurveRequestDto
     */
    'wearCurveType': RHIMContractsThresholdType;
}


/**
 * 
 * @export
 * @interface RHIMAPOReportingWearManagementApiV1ModelsVesselFunctionalProductCreateRequestDto
 */
export interface RHIMAPOReportingWearManagementApiV1ModelsVesselFunctionalProductCreateRequestDto {
    /**
     * Id of the vessel the functional product belongs to
     * @type {string}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsVesselFunctionalProductCreateRequestDto
     */
    'vesselId': string;
    /**
     * Name of the functional product  Unique per Vessel
     * @type {string}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsVesselFunctionalProductCreateRequestDto
     */
    'name': string;
    /**
     * 
     * @type {RHIMAPOCommonDataReportingFunctionalProductType}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsVesselFunctionalProductCreateRequestDto
     */
    'type': RHIMAPOCommonDataReportingFunctionalProductType;
    /**
     * 
     * @type {RHIMContractsRegionLocation}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsVesselFunctionalProductCreateRequestDto
     */
    'regionLocation': RHIMContractsRegionLocation;
    /**
     * 
     * @type {RHIMAPOReportingWearManagementApiV1ModelsVesselFunctionalProductCreateRequestDtoArea}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsVesselFunctionalProductCreateRequestDto
     */
    'area': RHIMAPOReportingWearManagementApiV1ModelsVesselFunctionalProductCreateRequestDtoArea;
    /**
     * The tilt angle of the product on the vessel
     * @type {number}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsVesselFunctionalProductCreateRequestDto
     */
    'tiltAngle': number;
}


/**
 * @type RHIMAPOReportingWearManagementApiV1ModelsVesselFunctionalProductCreateRequestDtoArea
 * The representation of the geometry of the functional product
 * @export
 */
export type RHIMAPOReportingWearManagementApiV1ModelsVesselFunctionalProductCreateRequestDtoArea = RHIMAPOReportingWearManagementApiV1ModelsCircleDto | RHIMAPOReportingWearManagementApiV1ModelsCrkCylinderDto;

/**
 * 
 * @export
 * @interface RHIMAPOReportingWearManagementApiV1ModelsVesselFunctionalProductDto
 */
export interface RHIMAPOReportingWearManagementApiV1ModelsVesselFunctionalProductDto {
    /**
     * Unique identifier of the functional product
     * @type {string}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsVesselFunctionalProductDto
     */
    'id': string;
    /**
     * Id of the vessel the functional product belongs to
     * @type {string}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsVesselFunctionalProductDto
     */
    'vesselId': string;
    /**
     * Name of the functional product  Unique per Vessel
     * @type {string}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsVesselFunctionalProductDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {RHIMAPOCommonDataReportingFunctionalProductType}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsVesselFunctionalProductDto
     */
    'type': RHIMAPOCommonDataReportingFunctionalProductType;
    /**
     * 
     * @type {RHIMContractsRegionLocation}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsVesselFunctionalProductDto
     */
    'regionLocation': RHIMContractsRegionLocation;
    /**
     * 
     * @type {RHIMAPOReportingWearManagementApiV1ModelsVesselFunctionalProductCreateRequestDtoArea}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsVesselFunctionalProductDto
     */
    'area': RHIMAPOReportingWearManagementApiV1ModelsVesselFunctionalProductCreateRequestDtoArea;
    /**
     * The tilt angle of the product on the vessel
     * @type {number}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsVesselFunctionalProductDto
     */
    'tiltAngle': number;
}


/**
 * 
 * @export
 * @interface RHIMAPOReportingWearManagementApiV1ModelsVesselFunctionalProductUpdateRequestDto
 */
export interface RHIMAPOReportingWearManagementApiV1ModelsVesselFunctionalProductUpdateRequestDto {
    /**
     * Name of the functional product  Unique per Vessel
     * @type {string}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsVesselFunctionalProductUpdateRequestDto
     */
    'name': string;
    /**
     * 
     * @type {RHIMAPOCommonDataReportingFunctionalProductType}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsVesselFunctionalProductUpdateRequestDto
     */
    'type': RHIMAPOCommonDataReportingFunctionalProductType;
    /**
     * 
     * @type {RHIMContractsRegionLocation}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsVesselFunctionalProductUpdateRequestDto
     */
    'regionLocation': RHIMContractsRegionLocation;
    /**
     * 
     * @type {RHIMAPOReportingWearManagementApiV1ModelsVesselFunctionalProductCreateRequestDtoArea}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsVesselFunctionalProductUpdateRequestDto
     */
    'area': RHIMAPOReportingWearManagementApiV1ModelsVesselFunctionalProductCreateRequestDtoArea;
    /**
     * The tilt angle of the product on the vessel
     * @type {number}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsVesselFunctionalProductUpdateRequestDto
     */
    'tiltAngle': number;
}


/**
 * 
 * @export
 * @interface RHIMAPOReportingWearManagementApiV1ModelsWearPredictionScheduleOptionDto
 */
export interface RHIMAPOReportingWearManagementApiV1ModelsWearPredictionScheduleOptionDto {
    /**
     * 
     * @type {string}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsWearPredictionScheduleOptionDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsWearPredictionScheduleOptionDto
     */
    'customerId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsWearPredictionScheduleOptionDto
     */
    'vesselType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsWearPredictionScheduleOptionDto
     */
    'vesselId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsWearPredictionScheduleOptionDto
     */
    'cron'?: string | null;
}
/**
 * 
 * @export
 * @interface RHIMAPOReportingWearManagementApiV1ModelsWearPredictionScheduleOptionRequestDto
 */
export interface RHIMAPOReportingWearManagementApiV1ModelsWearPredictionScheduleOptionRequestDto {
    /**
     * 
     * @type {string}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsWearPredictionScheduleOptionRequestDto
     */
    'customerId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsWearPredictionScheduleOptionRequestDto
     */
    'vesselType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsWearPredictionScheduleOptionRequestDto
     */
    'vesselId'?: string | null;
    /**
     * This is the scheduling time for wearprediction in cron expression format. <br />  Notes: We only support cron expressions up to days and not above 7 days! <br />  This means that the third and fourth point of the expression <strong>must</strong> be `*`<br />  (This has to do with the deduplication window of azure service bus we use to reduce duplicate prediction request messages)<br />  Immediate trigger is expressed as every minute: `* * * * *`
     * @type {string}
     * @memberof RHIMAPOReportingWearManagementApiV1ModelsWearPredictionScheduleOptionRequestDto
     */
    'cron': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const RHIMContractsFileType = {
    PostMortem: 'PostMortem',
    ProcessData: 'ProcessData',
    ProcessMetrixFiltered: 'ProcessMetrixFiltered',
    ProcessMetrixRaw: 'ProcessMetrixRaw',
    ProcessMetrixVertex: 'ProcessMetrixVertex',
    ProcessMetrixSummary: 'ProcessMetrixSummary',
    ProcessMetrixZip: 'ProcessMetrixZip',
    Stl: 'STL',
    HarmonizedSummary: 'HarmonizedSummary',
    ProcessDataSplit: 'ProcessDataSplit',
    Pts: 'PTS',
    Clinker: 'Clinker',
    KilnFeed: 'KilnFeed',
    HotMeal: 'HotMeal',
    Lignite: 'Lignite',
    StopLogs: 'StopLogs',
    IrScan: 'IRScan',
    KilnShellCooling: 'KilnShellCooling',
    KilnProcessData: 'KilnProcessData',
    FerrotronSummary: 'FerrotronSummary'
} as const;

export type RHIMContractsFileType = typeof RHIMContractsFileType[keyof typeof RHIMContractsFileType];


/**
 * 
 * @export
 * @enum {string}
 */

export const RHIMContractsMaintenanceTaskSeverity = {
    None: 'None',
    Low: 'Low',
    Medium: 'Medium',
    High: 'High'
} as const;

export type RHIMContractsMaintenanceTaskSeverity = typeof RHIMContractsMaintenanceTaskSeverity[keyof typeof RHIMContractsMaintenanceTaskSeverity];


/**
 * 
 * @export
 * @enum {string}
 */

export const RHIMContractsRegionLocation = {
    Bottom: 'Bottom',
    Barrel: 'Barrel',
    Cone: 'Cone',
    ThroatIn: 'ThroatIn',
    ThroatOut: 'ThroatOut'
} as const;

export type RHIMContractsRegionLocation = typeof RHIMContractsRegionLocation[keyof typeof RHIMContractsRegionLocation];


/**
 * 
 * @export
 * @enum {string}
 */

export const RHIMContractsThresholdType = {
    Target: 'Target',
    Lower: 'Lower',
    LowerPlotBoundary: 'LowerPlotBoundary',
    Critical: 'Critical',
    UpperPlotBoundary: 'UpperPlotBoundary',
    Upper: 'Upper'
} as const;

export type RHIMContractsThresholdType = typeof RHIMContractsThresholdType[keyof typeof RHIMContractsThresholdType];


/**
 * 
 * @export
 * @enum {string}
 */

export const RHIMContractsVesselType = {
    Bof: 'BOF',
    Rh: 'RH',
    Eaf: 'EAF',
    Ladle: 'Ladle',
    Crk: 'CRK',
    Rhl: 'RHL'
} as const;

export type RHIMContractsVesselType = typeof RHIMContractsVesselType[keyof typeof RHIMContractsVesselType];



/**
 * ColorscalesApi - axios parameter creator
 * @export
 */
export const ColorscalesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Deletes the colorscale for a customer and a specific vesseltype
         * @summary Deletes the colorscale for a customer and a specific vesseltype
         * @param {string} customerId Format - uuid. The Id from the customer from which the colorscales should be retrieved
         * @param {string} vesseltype The vesseltype from which the colorscales should be retrieved
         * @param {number} [threshold] Format - int32. Optional query paramater. If it is provided only the entry for the provideed threshold will be deleted
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteColorscalesCustomeridVesseltype: async (customerId: string, vesseltype: string, threshold?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('deleteColorscalesCustomeridVesseltype', 'customerId', customerId)
            // verify required parameter 'vesseltype' is not null or undefined
            assertParamExists('deleteColorscalesCustomeridVesseltype', 'vesseltype', vesseltype)
            const localVarPath = `/Colorscales/{customerId}/{vesseltype}`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)))
                .replace(`{${"vesseltype"}}`, encodeURIComponent(String(vesseltype)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (threshold !== undefined) {
                localVarQueryParameter['threshold'] = threshold;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Deletes the colorscale for a customer and a specific vesseltype
         * @summary Deletes the colorscale for a customer and a specific vesseltype
         * @param {string} customerId Format - uuid. The Id from the customer from which the colorscales should be retrieved
         * @param {string} vesseltype The vesseltype from which the colorscales should be retrieved
         * @param {string} colorScaleType The type of the colorscale. If no type is provided RBL is used as a default
         * @param {number} [threshold] Format - int32. Optional query paramater. If it is provided only the entry for the provideed threshold will be deleted
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteColorscalesCustomeridVesseltypeColorscaletype: async (customerId: string, vesseltype: string, colorScaleType: string, threshold?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('deleteColorscalesCustomeridVesseltypeColorscaletype', 'customerId', customerId)
            // verify required parameter 'vesseltype' is not null or undefined
            assertParamExists('deleteColorscalesCustomeridVesseltypeColorscaletype', 'vesseltype', vesseltype)
            // verify required parameter 'colorScaleType' is not null or undefined
            assertParamExists('deleteColorscalesCustomeridVesseltypeColorscaletype', 'colorScaleType', colorScaleType)
            const localVarPath = `/Colorscales/{customerId}/{vesseltype}/{colorScaleType}`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)))
                .replace(`{${"vesseltype"}}`, encodeURIComponent(String(vesseltype)))
                .replace(`{${"colorScaleType"}}`, encodeURIComponent(String(colorScaleType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (threshold !== undefined) {
                localVarQueryParameter['threshold'] = threshold;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves colorscales for a customer and a specific vesseltype and colorscaletype
         * @summary Retrieves colorscales for a customer and a specific vesseltype and colorscaletype
         * @param {string} customerId Format - uuid. The Id from the customer from which the colorscales should be retrieved
         * @param {string} vesseltype The vesseltype from which the colorscales should be retrieved
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getColorscalesCustomeridVesseltype: async (customerId: string, vesseltype: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('getColorscalesCustomeridVesseltype', 'customerId', customerId)
            // verify required parameter 'vesseltype' is not null or undefined
            assertParamExists('getColorscalesCustomeridVesseltype', 'vesseltype', vesseltype)
            const localVarPath = `/Colorscales/{customerId}/{vesseltype}`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)))
                .replace(`{${"vesseltype"}}`, encodeURIComponent(String(vesseltype)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves colorscales for a customer and a specific vesseltype and colorscaletype
         * @summary Retrieves colorscales for a customer and a specific vesseltype and colorscaletype
         * @param {string} customerId Format - uuid. The Id from the customer from which the colorscales should be retrieved
         * @param {string} vesseltype The vesseltype from which the colorscales should be retrieved
         * @param {string} colorScaleType The type of the colorscale. If no type is provided RBL is used as a default
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getColorscalesCustomeridVesseltypeColorscaletype: async (customerId: string, vesseltype: string, colorScaleType: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('getColorscalesCustomeridVesseltypeColorscaletype', 'customerId', customerId)
            // verify required parameter 'vesseltype' is not null or undefined
            assertParamExists('getColorscalesCustomeridVesseltypeColorscaletype', 'vesseltype', vesseltype)
            // verify required parameter 'colorScaleType' is not null or undefined
            assertParamExists('getColorscalesCustomeridVesseltypeColorscaletype', 'colorScaleType', colorScaleType)
            const localVarPath = `/Colorscales/{customerId}/{vesseltype}/{colorScaleType}`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)))
                .replace(`{${"vesseltype"}}`, encodeURIComponent(String(vesseltype)))
                .replace(`{${"colorScaleType"}}`, encodeURIComponent(String(colorScaleType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Upserts the new colorscale for a customer. If there was an old colorscale for the customer with the provided vesseltype it will be deleted before the new one is created.
         * @summary Upserts the new colorscale for a customer. If there was an old colorscale for the customer with the provided vesseltype it will be deleted before the new one is created.
         * @param {string} customerId Format - uuid.
         * @param {RHIMAPOReportingWearManagementApiV1ModelsColorscaleDto} [rHIMAPOReportingWearManagementApiV1ModelsColorscaleDto] Contains the information for the new colorscale
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putColorscalesCustomerid: async (customerId: string, rHIMAPOReportingWearManagementApiV1ModelsColorscaleDto?: RHIMAPOReportingWearManagementApiV1ModelsColorscaleDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('putColorscalesCustomerid', 'customerId', customerId)
            const localVarPath = `/Colorscales/{customerId}`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(rHIMAPOReportingWearManagementApiV1ModelsColorscaleDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ColorscalesApi - functional programming interface
 * @export
 */
export const ColorscalesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ColorscalesApiAxiosParamCreator(configuration)
    return {
        /**
         * Deletes the colorscale for a customer and a specific vesseltype
         * @summary Deletes the colorscale for a customer and a specific vesseltype
         * @param {string} customerId Format - uuid. The Id from the customer from which the colorscales should be retrieved
         * @param {string} vesseltype The vesseltype from which the colorscales should be retrieved
         * @param {number} [threshold] Format - int32. Optional query paramater. If it is provided only the entry for the provideed threshold will be deleted
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteColorscalesCustomeridVesseltype(customerId: string, vesseltype: string, threshold?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteColorscalesCustomeridVesseltype(customerId, vesseltype, threshold, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Deletes the colorscale for a customer and a specific vesseltype
         * @summary Deletes the colorscale for a customer and a specific vesseltype
         * @param {string} customerId Format - uuid. The Id from the customer from which the colorscales should be retrieved
         * @param {string} vesseltype The vesseltype from which the colorscales should be retrieved
         * @param {string} colorScaleType The type of the colorscale. If no type is provided RBL is used as a default
         * @param {number} [threshold] Format - int32. Optional query paramater. If it is provided only the entry for the provideed threshold will be deleted
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteColorscalesCustomeridVesseltypeColorscaletype(customerId: string, vesseltype: string, colorScaleType: string, threshold?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteColorscalesCustomeridVesseltypeColorscaletype(customerId, vesseltype, colorScaleType, threshold, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves colorscales for a customer and a specific vesseltype and colorscaletype
         * @summary Retrieves colorscales for a customer and a specific vesseltype and colorscaletype
         * @param {string} customerId Format - uuid. The Id from the customer from which the colorscales should be retrieved
         * @param {string} vesseltype The vesseltype from which the colorscales should be retrieved
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getColorscalesCustomeridVesseltype(customerId: string, vesseltype: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RHIMAPOReportingWearManagementApiV1ModelsColorscaleDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getColorscalesCustomeridVesseltype(customerId, vesseltype, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves colorscales for a customer and a specific vesseltype and colorscaletype
         * @summary Retrieves colorscales for a customer and a specific vesseltype and colorscaletype
         * @param {string} customerId Format - uuid. The Id from the customer from which the colorscales should be retrieved
         * @param {string} vesseltype The vesseltype from which the colorscales should be retrieved
         * @param {string} colorScaleType The type of the colorscale. If no type is provided RBL is used as a default
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getColorscalesCustomeridVesseltypeColorscaletype(customerId: string, vesseltype: string, colorScaleType: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RHIMAPOReportingWearManagementApiV1ModelsColorscaleDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getColorscalesCustomeridVesseltypeColorscaletype(customerId, vesseltype, colorScaleType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Upserts the new colorscale for a customer. If there was an old colorscale for the customer with the provided vesseltype it will be deleted before the new one is created.
         * @summary Upserts the new colorscale for a customer. If there was an old colorscale for the customer with the provided vesseltype it will be deleted before the new one is created.
         * @param {string} customerId Format - uuid.
         * @param {RHIMAPOReportingWearManagementApiV1ModelsColorscaleDto} [rHIMAPOReportingWearManagementApiV1ModelsColorscaleDto] Contains the information for the new colorscale
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putColorscalesCustomerid(customerId: string, rHIMAPOReportingWearManagementApiV1ModelsColorscaleDto?: RHIMAPOReportingWearManagementApiV1ModelsColorscaleDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putColorscalesCustomerid(customerId, rHIMAPOReportingWearManagementApiV1ModelsColorscaleDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ColorscalesApi - factory interface
 * @export
 */
export const ColorscalesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ColorscalesApiFp(configuration)
    return {
        /**
         * Deletes the colorscale for a customer and a specific vesseltype
         * @summary Deletes the colorscale for a customer and a specific vesseltype
         * @param {string} customerId Format - uuid. The Id from the customer from which the colorscales should be retrieved
         * @param {string} vesseltype The vesseltype from which the colorscales should be retrieved
         * @param {number} [threshold] Format - int32. Optional query paramater. If it is provided only the entry for the provideed threshold will be deleted
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteColorscalesCustomeridVesseltype(customerId: string, vesseltype: string, threshold?: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteColorscalesCustomeridVesseltype(customerId, vesseltype, threshold, options).then((request) => request(axios, basePath));
        },
        /**
         * Deletes the colorscale for a customer and a specific vesseltype
         * @summary Deletes the colorscale for a customer and a specific vesseltype
         * @param {string} customerId Format - uuid. The Id from the customer from which the colorscales should be retrieved
         * @param {string} vesseltype The vesseltype from which the colorscales should be retrieved
         * @param {string} colorScaleType The type of the colorscale. If no type is provided RBL is used as a default
         * @param {number} [threshold] Format - int32. Optional query paramater. If it is provided only the entry for the provideed threshold will be deleted
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteColorscalesCustomeridVesseltypeColorscaletype(customerId: string, vesseltype: string, colorScaleType: string, threshold?: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteColorscalesCustomeridVesseltypeColorscaletype(customerId, vesseltype, colorScaleType, threshold, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves colorscales for a customer and a specific vesseltype and colorscaletype
         * @summary Retrieves colorscales for a customer and a specific vesseltype and colorscaletype
         * @param {string} customerId Format - uuid. The Id from the customer from which the colorscales should be retrieved
         * @param {string} vesseltype The vesseltype from which the colorscales should be retrieved
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getColorscalesCustomeridVesseltype(customerId: string, vesseltype: string, options?: any): AxiosPromise<RHIMAPOReportingWearManagementApiV1ModelsColorscaleDto> {
            return localVarFp.getColorscalesCustomeridVesseltype(customerId, vesseltype, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves colorscales for a customer and a specific vesseltype and colorscaletype
         * @summary Retrieves colorscales for a customer and a specific vesseltype and colorscaletype
         * @param {string} customerId Format - uuid. The Id from the customer from which the colorscales should be retrieved
         * @param {string} vesseltype The vesseltype from which the colorscales should be retrieved
         * @param {string} colorScaleType The type of the colorscale. If no type is provided RBL is used as a default
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getColorscalesCustomeridVesseltypeColorscaletype(customerId: string, vesseltype: string, colorScaleType: string, options?: any): AxiosPromise<RHIMAPOReportingWearManagementApiV1ModelsColorscaleDto> {
            return localVarFp.getColorscalesCustomeridVesseltypeColorscaletype(customerId, vesseltype, colorScaleType, options).then((request) => request(axios, basePath));
        },
        /**
         * Upserts the new colorscale for a customer. If there was an old colorscale for the customer with the provided vesseltype it will be deleted before the new one is created.
         * @summary Upserts the new colorscale for a customer. If there was an old colorscale for the customer with the provided vesseltype it will be deleted before the new one is created.
         * @param {string} customerId Format - uuid.
         * @param {RHIMAPOReportingWearManagementApiV1ModelsColorscaleDto} [rHIMAPOReportingWearManagementApiV1ModelsColorscaleDto] Contains the information for the new colorscale
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putColorscalesCustomerid(customerId: string, rHIMAPOReportingWearManagementApiV1ModelsColorscaleDto?: RHIMAPOReportingWearManagementApiV1ModelsColorscaleDto, options?: any): AxiosPromise<void> {
            return localVarFp.putColorscalesCustomerid(customerId, rHIMAPOReportingWearManagementApiV1ModelsColorscaleDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ColorscalesApi - object-oriented interface
 * @export
 * @class ColorscalesApi
 * @extends {BaseAPI}
 */
export class ColorscalesApi extends BaseAPI {
    /**
     * Deletes the colorscale for a customer and a specific vesseltype
     * @summary Deletes the colorscale for a customer and a specific vesseltype
     * @param {string} customerId Format - uuid. The Id from the customer from which the colorscales should be retrieved
     * @param {string} vesseltype The vesseltype from which the colorscales should be retrieved
     * @param {number} [threshold] Format - int32. Optional query paramater. If it is provided only the entry for the provideed threshold will be deleted
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ColorscalesApi
     */
    public deleteColorscalesCustomeridVesseltype(customerId: string, vesseltype: string, threshold?: number, options?: AxiosRequestConfig) {
        return ColorscalesApiFp(this.configuration).deleteColorscalesCustomeridVesseltype(customerId, vesseltype, threshold, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Deletes the colorscale for a customer and a specific vesseltype
     * @summary Deletes the colorscale for a customer and a specific vesseltype
     * @param {string} customerId Format - uuid. The Id from the customer from which the colorscales should be retrieved
     * @param {string} vesseltype The vesseltype from which the colorscales should be retrieved
     * @param {string} colorScaleType The type of the colorscale. If no type is provided RBL is used as a default
     * @param {number} [threshold] Format - int32. Optional query paramater. If it is provided only the entry for the provideed threshold will be deleted
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ColorscalesApi
     */
    public deleteColorscalesCustomeridVesseltypeColorscaletype(customerId: string, vesseltype: string, colorScaleType: string, threshold?: number, options?: AxiosRequestConfig) {
        return ColorscalesApiFp(this.configuration).deleteColorscalesCustomeridVesseltypeColorscaletype(customerId, vesseltype, colorScaleType, threshold, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves colorscales for a customer and a specific vesseltype and colorscaletype
     * @summary Retrieves colorscales for a customer and a specific vesseltype and colorscaletype
     * @param {string} customerId Format - uuid. The Id from the customer from which the colorscales should be retrieved
     * @param {string} vesseltype The vesseltype from which the colorscales should be retrieved
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ColorscalesApi
     */
    public getColorscalesCustomeridVesseltype(customerId: string, vesseltype: string, options?: AxiosRequestConfig) {
        return ColorscalesApiFp(this.configuration).getColorscalesCustomeridVesseltype(customerId, vesseltype, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves colorscales for a customer and a specific vesseltype and colorscaletype
     * @summary Retrieves colorscales for a customer and a specific vesseltype and colorscaletype
     * @param {string} customerId Format - uuid. The Id from the customer from which the colorscales should be retrieved
     * @param {string} vesseltype The vesseltype from which the colorscales should be retrieved
     * @param {string} colorScaleType The type of the colorscale. If no type is provided RBL is used as a default
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ColorscalesApi
     */
    public getColorscalesCustomeridVesseltypeColorscaletype(customerId: string, vesseltype: string, colorScaleType: string, options?: AxiosRequestConfig) {
        return ColorscalesApiFp(this.configuration).getColorscalesCustomeridVesseltypeColorscaletype(customerId, vesseltype, colorScaleType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Upserts the new colorscale for a customer. If there was an old colorscale for the customer with the provided vesseltype it will be deleted before the new one is created.
     * @summary Upserts the new colorscale for a customer. If there was an old colorscale for the customer with the provided vesseltype it will be deleted before the new one is created.
     * @param {string} customerId Format - uuid.
     * @param {RHIMAPOReportingWearManagementApiV1ModelsColorscaleDto} [rHIMAPOReportingWearManagementApiV1ModelsColorscaleDto] Contains the information for the new colorscale
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ColorscalesApi
     */
    public putColorscalesCustomerid(customerId: string, rHIMAPOReportingWearManagementApiV1ModelsColorscaleDto?: RHIMAPOReportingWearManagementApiV1ModelsColorscaleDto, options?: AxiosRequestConfig) {
        return ColorscalesApiFp(this.configuration).putColorscalesCustomerid(customerId, rHIMAPOReportingWearManagementApiV1ModelsColorscaleDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * CrkPredictionsApi - axios parameter creator
 * @export
 */
export const CrkPredictionsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Returns a list of campaigns which contain crk predictions that are available for the specified vessel.
         * @summary Returns a list of campaigns which contain crk predictions that are available for the specified vessel.
         * @param {string} vesselId Format - uuid. The Id of the vessel.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPredictionsCrkVesselidCampaigns: async (vesselId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'vesselId' is not null or undefined
            assertParamExists('getPredictionsCrkVesselidCampaigns', 'vesselId', vesselId)
            const localVarPath = `/predictions/crk/{vesselId}/campaigns`
                .replace(`{${"vesselId"}}`, encodeURIComponent(String(vesselId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns a list of crk predictions for a vessel and a campaign.
         * @summary Returns a list of crk predictions for a vessel and a campaign.
         * @param {string} vesselId Format - uuid. The Id of the vessel.
         * @param {number} campaign Format - int32. The campaign
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPredictionsCrkVesselidCampaignsCampaignPredictions: async (vesselId: string, campaign: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'vesselId' is not null or undefined
            assertParamExists('getPredictionsCrkVesselidCampaignsCampaignPredictions', 'vesselId', vesselId)
            // verify required parameter 'campaign' is not null or undefined
            assertParamExists('getPredictionsCrkVesselidCampaignsCampaignPredictions', 'campaign', campaign)
            const localVarPath = `/predictions/crk/{vesselId}/campaigns/{campaign}/predictions`
                .replace(`{${"vesselId"}}`, encodeURIComponent(String(vesselId)))
                .replace(`{${"campaign"}}`, encodeURIComponent(String(campaign)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets a CRK prediction.
         * @summary Gets a CRK prediction.
         * @param {string} vesselId Format - uuid. The ID of the vessel
         * @param {string} predictionId Format - uuid. The id of the prediction.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPredictionsCrkVesselidPredictionid: async (vesselId: string, predictionId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'vesselId' is not null or undefined
            assertParamExists('getPredictionsCrkVesselidPredictionid', 'vesselId', vesselId)
            // verify required parameter 'predictionId' is not null or undefined
            assertParamExists('getPredictionsCrkVesselidPredictionid', 'predictionId', predictionId)
            const localVarPath = `/predictions/crk/{vesselId}/{predictionId}`
                .replace(`{${"vesselId"}}`, encodeURIComponent(String(vesselId)))
                .replace(`{${"predictionId"}}`, encodeURIComponent(String(predictionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CrkPredictionsApi - functional programming interface
 * @export
 */
export const CrkPredictionsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CrkPredictionsApiAxiosParamCreator(configuration)
    return {
        /**
         * Returns a list of campaigns which contain crk predictions that are available for the specified vessel.
         * @summary Returns a list of campaigns which contain crk predictions that are available for the specified vessel.
         * @param {string} vesselId Format - uuid. The Id of the vessel.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPredictionsCrkVesselidCampaigns(vesselId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RHIMAPOReportingWearManagementApiV1ModelsCampaignOverviewDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPredictionsCrkVesselidCampaigns(vesselId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns a list of crk predictions for a vessel and a campaign.
         * @summary Returns a list of crk predictions for a vessel and a campaign.
         * @param {string} vesselId Format - uuid. The Id of the vessel.
         * @param {number} campaign Format - int32. The campaign
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPredictionsCrkVesselidCampaignsCampaignPredictions(vesselId: string, campaign: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RHIMAPOReportingWearManagementApiV1ModelsPredictionOverviewDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPredictionsCrkVesselidCampaignsCampaignPredictions(vesselId, campaign, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Gets a CRK prediction.
         * @summary Gets a CRK prediction.
         * @param {string} vesselId Format - uuid. The ID of the vessel
         * @param {string} predictionId Format - uuid. The id of the prediction.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPredictionsCrkVesselidPredictionid(vesselId: string, predictionId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RHIMAPOReportingWearManagementApiV1ModelsCRKCrkPredictionModelDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPredictionsCrkVesselidPredictionid(vesselId, predictionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CrkPredictionsApi - factory interface
 * @export
 */
export const CrkPredictionsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CrkPredictionsApiFp(configuration)
    return {
        /**
         * Returns a list of campaigns which contain crk predictions that are available for the specified vessel.
         * @summary Returns a list of campaigns which contain crk predictions that are available for the specified vessel.
         * @param {string} vesselId Format - uuid. The Id of the vessel.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPredictionsCrkVesselidCampaigns(vesselId: string, options?: any): AxiosPromise<Array<RHIMAPOReportingWearManagementApiV1ModelsCampaignOverviewDto>> {
            return localVarFp.getPredictionsCrkVesselidCampaigns(vesselId, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns a list of crk predictions for a vessel and a campaign.
         * @summary Returns a list of crk predictions for a vessel and a campaign.
         * @param {string} vesselId Format - uuid. The Id of the vessel.
         * @param {number} campaign Format - int32. The campaign
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPredictionsCrkVesselidCampaignsCampaignPredictions(vesselId: string, campaign: number, options?: any): AxiosPromise<Array<RHIMAPOReportingWearManagementApiV1ModelsPredictionOverviewDto>> {
            return localVarFp.getPredictionsCrkVesselidCampaignsCampaignPredictions(vesselId, campaign, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets a CRK prediction.
         * @summary Gets a CRK prediction.
         * @param {string} vesselId Format - uuid. The ID of the vessel
         * @param {string} predictionId Format - uuid. The id of the prediction.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPredictionsCrkVesselidPredictionid(vesselId: string, predictionId: string, options?: any): AxiosPromise<RHIMAPOReportingWearManagementApiV1ModelsCRKCrkPredictionModelDto> {
            return localVarFp.getPredictionsCrkVesselidPredictionid(vesselId, predictionId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CrkPredictionsApi - object-oriented interface
 * @export
 * @class CrkPredictionsApi
 * @extends {BaseAPI}
 */
export class CrkPredictionsApi extends BaseAPI {
    /**
     * Returns a list of campaigns which contain crk predictions that are available for the specified vessel.
     * @summary Returns a list of campaigns which contain crk predictions that are available for the specified vessel.
     * @param {string} vesselId Format - uuid. The Id of the vessel.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CrkPredictionsApi
     */
    public getPredictionsCrkVesselidCampaigns(vesselId: string, options?: AxiosRequestConfig) {
        return CrkPredictionsApiFp(this.configuration).getPredictionsCrkVesselidCampaigns(vesselId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns a list of crk predictions for a vessel and a campaign.
     * @summary Returns a list of crk predictions for a vessel and a campaign.
     * @param {string} vesselId Format - uuid. The Id of the vessel.
     * @param {number} campaign Format - int32. The campaign
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CrkPredictionsApi
     */
    public getPredictionsCrkVesselidCampaignsCampaignPredictions(vesselId: string, campaign: number, options?: AxiosRequestConfig) {
        return CrkPredictionsApiFp(this.configuration).getPredictionsCrkVesselidCampaignsCampaignPredictions(vesselId, campaign, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets a CRK prediction.
     * @summary Gets a CRK prediction.
     * @param {string} vesselId Format - uuid. The ID of the vessel
     * @param {string} predictionId Format - uuid. The id of the prediction.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CrkPredictionsApi
     */
    public getPredictionsCrkVesselidPredictionid(vesselId: string, predictionId: string, options?: AxiosRequestConfig) {
        return CrkPredictionsApiFp(this.configuration).getPredictionsCrkVesselidPredictionid(vesselId, predictionId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * CrkZoneSetApi - axios parameter creator
 * @export
 */
export const CrkZoneSetApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Delete a crk zone set
         * @summary Delete a crk zone set
         * @param {string} crkZoneSetId Format - uuid. Identifier of the crk zone set
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCrkzonesetCrkzonesetid: async (crkZoneSetId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'crkZoneSetId' is not null or undefined
            assertParamExists('deleteCrkzonesetCrkzonesetid', 'crkZoneSetId', crkZoneSetId)
            const localVarPath = `/CrkZoneSet/{crkZoneSetId}`
                .replace(`{${"crkZoneSetId"}}`, encodeURIComponent(String(crkZoneSetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a crk zone set by id
         * @summary Retrieves a crk zone set by id
         * @param {string} crkZoneSetId Format - uuid. Identifier of the crk zone set
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCrkzonesetCrkzonesetid: async (crkZoneSetId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'crkZoneSetId' is not null or undefined
            assertParamExists('getCrkzonesetCrkzonesetid', 'crkZoneSetId', crkZoneSetId)
            const localVarPath = `/CrkZoneSet/{crkZoneSetId}`
                .replace(`{${"crkZoneSetId"}}`, encodeURIComponent(String(crkZoneSetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves all crk zone sets for a given vessel
         * @summary Retrieves all crk zone sets for a given vessel
         * @param {string} vesselId Format - uuid. Id of the vessel
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCrkzonesetVesselsVesselidCrkzonesets: async (vesselId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'vesselId' is not null or undefined
            assertParamExists('getCrkzonesetVesselsVesselidCrkzonesets', 'vesselId', vesselId)
            const localVarPath = `/CrkZoneSet/vessels/{vesselId}/crkzonesets`
                .replace(`{${"vesselId"}}`, encodeURIComponent(String(vesselId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves the active crk zone sets for a given vessel and timestamp
         * @summary Retrieves the active crk zone sets for a given vessel and timestamp
         * @param {string} vesselId Format - uuid. Id of the vessel
         * @param {string} [timestamp] Format - date-time (as date-time in RFC3339). The timestamp for which the active crk zone set should be retrieved
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCrkzonesetVesselsVesselidCrkzonesetsActive: async (vesselId: string, timestamp?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'vesselId' is not null or undefined
            assertParamExists('getCrkzonesetVesselsVesselidCrkzonesetsActive', 'vesselId', vesselId)
            const localVarPath = `/CrkZoneSet/vessels/{vesselId}/crkzonesets/active`
                .replace(`{${"vesselId"}}`, encodeURIComponent(String(vesselId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (timestamp !== undefined) {
                localVarQueryParameter['timestamp'] = timestamp;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create a new crk zone set
         * @summary Create a new crk zone set
         * @param {string} vesselId Format - uuid. Identifier of the vessel
         * @param {RHIMAPOReportingWearManagementApiV1ModelsCreateOrUpdateCrkZoneSetDto} [rHIMAPOReportingWearManagementApiV1ModelsCreateOrUpdateCrkZoneSetDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCrkzonesetVesselsVesselidCrkzonesets: async (vesselId: string, rHIMAPOReportingWearManagementApiV1ModelsCreateOrUpdateCrkZoneSetDto?: RHIMAPOReportingWearManagementApiV1ModelsCreateOrUpdateCrkZoneSetDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'vesselId' is not null or undefined
            assertParamExists('postCrkzonesetVesselsVesselidCrkzonesets', 'vesselId', vesselId)
            const localVarPath = `/CrkZoneSet/vessels/{vesselId}/crkzonesets`
                .replace(`{${"vesselId"}}`, encodeURIComponent(String(vesselId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(rHIMAPOReportingWearManagementApiV1ModelsCreateOrUpdateCrkZoneSetDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update a crk zone set
         * @summary Update a crk zone set
         * @param {string} crkZoneSetId Format - uuid. Identifier of the crk zone set
         * @param {RHIMAPOReportingWearManagementApiV1ModelsCreateOrUpdateCrkZoneSetDto} [rHIMAPOReportingWearManagementApiV1ModelsCreateOrUpdateCrkZoneSetDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putCrkzonesetCrkzonesetid: async (crkZoneSetId: string, rHIMAPOReportingWearManagementApiV1ModelsCreateOrUpdateCrkZoneSetDto?: RHIMAPOReportingWearManagementApiV1ModelsCreateOrUpdateCrkZoneSetDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'crkZoneSetId' is not null or undefined
            assertParamExists('putCrkzonesetCrkzonesetid', 'crkZoneSetId', crkZoneSetId)
            const localVarPath = `/CrkZoneSet/{crkZoneSetId}`
                .replace(`{${"crkZoneSetId"}}`, encodeURIComponent(String(crkZoneSetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(rHIMAPOReportingWearManagementApiV1ModelsCreateOrUpdateCrkZoneSetDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CrkZoneSetApi - functional programming interface
 * @export
 */
export const CrkZoneSetApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CrkZoneSetApiAxiosParamCreator(configuration)
    return {
        /**
         * Delete a crk zone set
         * @summary Delete a crk zone set
         * @param {string} crkZoneSetId Format - uuid. Identifier of the crk zone set
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCrkzonesetCrkzonesetid(crkZoneSetId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCrkzonesetCrkzonesetid(crkZoneSetId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves a crk zone set by id
         * @summary Retrieves a crk zone set by id
         * @param {string} crkZoneSetId Format - uuid. Identifier of the crk zone set
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCrkzonesetCrkzonesetid(crkZoneSetId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RHIMAPOReportingWearManagementApiV1ModelsCrkZoneSetDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCrkzonesetCrkzonesetid(crkZoneSetId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves all crk zone sets for a given vessel
         * @summary Retrieves all crk zone sets for a given vessel
         * @param {string} vesselId Format - uuid. Id of the vessel
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCrkzonesetVesselsVesselidCrkzonesets(vesselId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RHIMAPOReportingWearManagementApiV1ModelsCrkZoneSetDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCrkzonesetVesselsVesselidCrkzonesets(vesselId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves the active crk zone sets for a given vessel and timestamp
         * @summary Retrieves the active crk zone sets for a given vessel and timestamp
         * @param {string} vesselId Format - uuid. Id of the vessel
         * @param {string} [timestamp] Format - date-time (as date-time in RFC3339). The timestamp for which the active crk zone set should be retrieved
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCrkzonesetVesselsVesselidCrkzonesetsActive(vesselId: string, timestamp?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RHIMAPOReportingWearManagementApiV1ModelsCrkZoneSetDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCrkzonesetVesselsVesselidCrkzonesetsActive(vesselId, timestamp, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create a new crk zone set
         * @summary Create a new crk zone set
         * @param {string} vesselId Format - uuid. Identifier of the vessel
         * @param {RHIMAPOReportingWearManagementApiV1ModelsCreateOrUpdateCrkZoneSetDto} [rHIMAPOReportingWearManagementApiV1ModelsCreateOrUpdateCrkZoneSetDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postCrkzonesetVesselsVesselidCrkzonesets(vesselId: string, rHIMAPOReportingWearManagementApiV1ModelsCreateOrUpdateCrkZoneSetDto?: RHIMAPOReportingWearManagementApiV1ModelsCreateOrUpdateCrkZoneSetDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RHIMAPOReportingWearManagementApiV1ModelsCrkZoneSetDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postCrkzonesetVesselsVesselidCrkzonesets(vesselId, rHIMAPOReportingWearManagementApiV1ModelsCreateOrUpdateCrkZoneSetDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update a crk zone set
         * @summary Update a crk zone set
         * @param {string} crkZoneSetId Format - uuid. Identifier of the crk zone set
         * @param {RHIMAPOReportingWearManagementApiV1ModelsCreateOrUpdateCrkZoneSetDto} [rHIMAPOReportingWearManagementApiV1ModelsCreateOrUpdateCrkZoneSetDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putCrkzonesetCrkzonesetid(crkZoneSetId: string, rHIMAPOReportingWearManagementApiV1ModelsCreateOrUpdateCrkZoneSetDto?: RHIMAPOReportingWearManagementApiV1ModelsCreateOrUpdateCrkZoneSetDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RHIMAPOReportingWearManagementApiV1ModelsCrkZoneSetDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putCrkzonesetCrkzonesetid(crkZoneSetId, rHIMAPOReportingWearManagementApiV1ModelsCreateOrUpdateCrkZoneSetDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CrkZoneSetApi - factory interface
 * @export
 */
export const CrkZoneSetApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CrkZoneSetApiFp(configuration)
    return {
        /**
         * Delete a crk zone set
         * @summary Delete a crk zone set
         * @param {string} crkZoneSetId Format - uuid. Identifier of the crk zone set
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCrkzonesetCrkzonesetid(crkZoneSetId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteCrkzonesetCrkzonesetid(crkZoneSetId, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a crk zone set by id
         * @summary Retrieves a crk zone set by id
         * @param {string} crkZoneSetId Format - uuid. Identifier of the crk zone set
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCrkzonesetCrkzonesetid(crkZoneSetId: string, options?: any): AxiosPromise<RHIMAPOReportingWearManagementApiV1ModelsCrkZoneSetDto> {
            return localVarFp.getCrkzonesetCrkzonesetid(crkZoneSetId, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves all crk zone sets for a given vessel
         * @summary Retrieves all crk zone sets for a given vessel
         * @param {string} vesselId Format - uuid. Id of the vessel
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCrkzonesetVesselsVesselidCrkzonesets(vesselId: string, options?: any): AxiosPromise<Array<RHIMAPOReportingWearManagementApiV1ModelsCrkZoneSetDto>> {
            return localVarFp.getCrkzonesetVesselsVesselidCrkzonesets(vesselId, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves the active crk zone sets for a given vessel and timestamp
         * @summary Retrieves the active crk zone sets for a given vessel and timestamp
         * @param {string} vesselId Format - uuid. Id of the vessel
         * @param {string} [timestamp] Format - date-time (as date-time in RFC3339). The timestamp for which the active crk zone set should be retrieved
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCrkzonesetVesselsVesselidCrkzonesetsActive(vesselId: string, timestamp?: string, options?: any): AxiosPromise<RHIMAPOReportingWearManagementApiV1ModelsCrkZoneSetDto> {
            return localVarFp.getCrkzonesetVesselsVesselidCrkzonesetsActive(vesselId, timestamp, options).then((request) => request(axios, basePath));
        },
        /**
         * Create a new crk zone set
         * @summary Create a new crk zone set
         * @param {string} vesselId Format - uuid. Identifier of the vessel
         * @param {RHIMAPOReportingWearManagementApiV1ModelsCreateOrUpdateCrkZoneSetDto} [rHIMAPOReportingWearManagementApiV1ModelsCreateOrUpdateCrkZoneSetDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCrkzonesetVesselsVesselidCrkzonesets(vesselId: string, rHIMAPOReportingWearManagementApiV1ModelsCreateOrUpdateCrkZoneSetDto?: RHIMAPOReportingWearManagementApiV1ModelsCreateOrUpdateCrkZoneSetDto, options?: any): AxiosPromise<RHIMAPOReportingWearManagementApiV1ModelsCrkZoneSetDto> {
            return localVarFp.postCrkzonesetVesselsVesselidCrkzonesets(vesselId, rHIMAPOReportingWearManagementApiV1ModelsCreateOrUpdateCrkZoneSetDto, options).then((request) => request(axios, basePath));
        },
        /**
         * Update a crk zone set
         * @summary Update a crk zone set
         * @param {string} crkZoneSetId Format - uuid. Identifier of the crk zone set
         * @param {RHIMAPOReportingWearManagementApiV1ModelsCreateOrUpdateCrkZoneSetDto} [rHIMAPOReportingWearManagementApiV1ModelsCreateOrUpdateCrkZoneSetDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putCrkzonesetCrkzonesetid(crkZoneSetId: string, rHIMAPOReportingWearManagementApiV1ModelsCreateOrUpdateCrkZoneSetDto?: RHIMAPOReportingWearManagementApiV1ModelsCreateOrUpdateCrkZoneSetDto, options?: any): AxiosPromise<RHIMAPOReportingWearManagementApiV1ModelsCrkZoneSetDto> {
            return localVarFp.putCrkzonesetCrkzonesetid(crkZoneSetId, rHIMAPOReportingWearManagementApiV1ModelsCreateOrUpdateCrkZoneSetDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CrkZoneSetApi - object-oriented interface
 * @export
 * @class CrkZoneSetApi
 * @extends {BaseAPI}
 */
export class CrkZoneSetApi extends BaseAPI {
    /**
     * Delete a crk zone set
     * @summary Delete a crk zone set
     * @param {string} crkZoneSetId Format - uuid. Identifier of the crk zone set
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CrkZoneSetApi
     */
    public deleteCrkzonesetCrkzonesetid(crkZoneSetId: string, options?: AxiosRequestConfig) {
        return CrkZoneSetApiFp(this.configuration).deleteCrkzonesetCrkzonesetid(crkZoneSetId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a crk zone set by id
     * @summary Retrieves a crk zone set by id
     * @param {string} crkZoneSetId Format - uuid. Identifier of the crk zone set
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CrkZoneSetApi
     */
    public getCrkzonesetCrkzonesetid(crkZoneSetId: string, options?: AxiosRequestConfig) {
        return CrkZoneSetApiFp(this.configuration).getCrkzonesetCrkzonesetid(crkZoneSetId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves all crk zone sets for a given vessel
     * @summary Retrieves all crk zone sets for a given vessel
     * @param {string} vesselId Format - uuid. Id of the vessel
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CrkZoneSetApi
     */
    public getCrkzonesetVesselsVesselidCrkzonesets(vesselId: string, options?: AxiosRequestConfig) {
        return CrkZoneSetApiFp(this.configuration).getCrkzonesetVesselsVesselidCrkzonesets(vesselId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves the active crk zone sets for a given vessel and timestamp
     * @summary Retrieves the active crk zone sets for a given vessel and timestamp
     * @param {string} vesselId Format - uuid. Id of the vessel
     * @param {string} [timestamp] Format - date-time (as date-time in RFC3339). The timestamp for which the active crk zone set should be retrieved
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CrkZoneSetApi
     */
    public getCrkzonesetVesselsVesselidCrkzonesetsActive(vesselId: string, timestamp?: string, options?: AxiosRequestConfig) {
        return CrkZoneSetApiFp(this.configuration).getCrkzonesetVesselsVesselidCrkzonesetsActive(vesselId, timestamp, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create a new crk zone set
     * @summary Create a new crk zone set
     * @param {string} vesselId Format - uuid. Identifier of the vessel
     * @param {RHIMAPOReportingWearManagementApiV1ModelsCreateOrUpdateCrkZoneSetDto} [rHIMAPOReportingWearManagementApiV1ModelsCreateOrUpdateCrkZoneSetDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CrkZoneSetApi
     */
    public postCrkzonesetVesselsVesselidCrkzonesets(vesselId: string, rHIMAPOReportingWearManagementApiV1ModelsCreateOrUpdateCrkZoneSetDto?: RHIMAPOReportingWearManagementApiV1ModelsCreateOrUpdateCrkZoneSetDto, options?: AxiosRequestConfig) {
        return CrkZoneSetApiFp(this.configuration).postCrkzonesetVesselsVesselidCrkzonesets(vesselId, rHIMAPOReportingWearManagementApiV1ModelsCreateOrUpdateCrkZoneSetDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update a crk zone set
     * @summary Update a crk zone set
     * @param {string} crkZoneSetId Format - uuid. Identifier of the crk zone set
     * @param {RHIMAPOReportingWearManagementApiV1ModelsCreateOrUpdateCrkZoneSetDto} [rHIMAPOReportingWearManagementApiV1ModelsCreateOrUpdateCrkZoneSetDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CrkZoneSetApi
     */
    public putCrkzonesetCrkzonesetid(crkZoneSetId: string, rHIMAPOReportingWearManagementApiV1ModelsCreateOrUpdateCrkZoneSetDto?: RHIMAPOReportingWearManagementApiV1ModelsCreateOrUpdateCrkZoneSetDto, options?: AxiosRequestConfig) {
        return CrkZoneSetApiFp(this.configuration).putCrkzonesetCrkzonesetid(crkZoneSetId, rHIMAPOReportingWearManagementApiV1ModelsCreateOrUpdateCrkZoneSetDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * DataScienceModelSelectionsApi - axios parameter creator
 * @export
 */
export const DataScienceModelSelectionsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrieves all data science model selections of a customer
         * @summary Retrieves all data science model selections of a customer
         * @param {string} customerId Format - uuid. Identifier of the customer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomersCustomeridModelselections: async (customerId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('getCustomersCustomeridModelselections', 'customerId', customerId)
            const localVarPath = `/customers/{customerId}/modelselections`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Set the data science model selections of the customer
         * @summary Set the data science model selections of the customer
         * @param {string} customerId Format - uuid. Customer id to identify the customer
         * @param {Array<RHIMAPOReportingWearManagementApiV1ModelsDataScienceModelSelectionDto>} [rHIMAPOReportingWearManagementApiV1ModelsDataScienceModelSelectionDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putCustomersCustomeridModelselections: async (customerId: string, rHIMAPOReportingWearManagementApiV1ModelsDataScienceModelSelectionDto?: Array<RHIMAPOReportingWearManagementApiV1ModelsDataScienceModelSelectionDto>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('putCustomersCustomeridModelselections', 'customerId', customerId)
            const localVarPath = `/customers/{customerId}/modelselections`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(rHIMAPOReportingWearManagementApiV1ModelsDataScienceModelSelectionDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DataScienceModelSelectionsApi - functional programming interface
 * @export
 */
export const DataScienceModelSelectionsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DataScienceModelSelectionsApiAxiosParamCreator(configuration)
    return {
        /**
         * Retrieves all data science model selections of a customer
         * @summary Retrieves all data science model selections of a customer
         * @param {string} customerId Format - uuid. Identifier of the customer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCustomersCustomeridModelselections(customerId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RHIMAPOReportingWearManagementApiV1ModelsDataScienceModelSelectionDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCustomersCustomeridModelselections(customerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Set the data science model selections of the customer
         * @summary Set the data science model selections of the customer
         * @param {string} customerId Format - uuid. Customer id to identify the customer
         * @param {Array<RHIMAPOReportingWearManagementApiV1ModelsDataScienceModelSelectionDto>} [rHIMAPOReportingWearManagementApiV1ModelsDataScienceModelSelectionDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putCustomersCustomeridModelselections(customerId: string, rHIMAPOReportingWearManagementApiV1ModelsDataScienceModelSelectionDto?: Array<RHIMAPOReportingWearManagementApiV1ModelsDataScienceModelSelectionDto>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putCustomersCustomeridModelselections(customerId, rHIMAPOReportingWearManagementApiV1ModelsDataScienceModelSelectionDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DataScienceModelSelectionsApi - factory interface
 * @export
 */
export const DataScienceModelSelectionsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DataScienceModelSelectionsApiFp(configuration)
    return {
        /**
         * Retrieves all data science model selections of a customer
         * @summary Retrieves all data science model selections of a customer
         * @param {string} customerId Format - uuid. Identifier of the customer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomersCustomeridModelselections(customerId: string, options?: any): AxiosPromise<Array<RHIMAPOReportingWearManagementApiV1ModelsDataScienceModelSelectionDto>> {
            return localVarFp.getCustomersCustomeridModelselections(customerId, options).then((request) => request(axios, basePath));
        },
        /**
         * Set the data science model selections of the customer
         * @summary Set the data science model selections of the customer
         * @param {string} customerId Format - uuid. Customer id to identify the customer
         * @param {Array<RHIMAPOReportingWearManagementApiV1ModelsDataScienceModelSelectionDto>} [rHIMAPOReportingWearManagementApiV1ModelsDataScienceModelSelectionDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putCustomersCustomeridModelselections(customerId: string, rHIMAPOReportingWearManagementApiV1ModelsDataScienceModelSelectionDto?: Array<RHIMAPOReportingWearManagementApiV1ModelsDataScienceModelSelectionDto>, options?: any): AxiosPromise<void> {
            return localVarFp.putCustomersCustomeridModelselections(customerId, rHIMAPOReportingWearManagementApiV1ModelsDataScienceModelSelectionDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DataScienceModelSelectionsApi - object-oriented interface
 * @export
 * @class DataScienceModelSelectionsApi
 * @extends {BaseAPI}
 */
export class DataScienceModelSelectionsApi extends BaseAPI {
    /**
     * Retrieves all data science model selections of a customer
     * @summary Retrieves all data science model selections of a customer
     * @param {string} customerId Format - uuid. Identifier of the customer
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataScienceModelSelectionsApi
     */
    public getCustomersCustomeridModelselections(customerId: string, options?: AxiosRequestConfig) {
        return DataScienceModelSelectionsApiFp(this.configuration).getCustomersCustomeridModelselections(customerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Set the data science model selections of the customer
     * @summary Set the data science model selections of the customer
     * @param {string} customerId Format - uuid. Customer id to identify the customer
     * @param {Array<RHIMAPOReportingWearManagementApiV1ModelsDataScienceModelSelectionDto>} [rHIMAPOReportingWearManagementApiV1ModelsDataScienceModelSelectionDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataScienceModelSelectionsApi
     */
    public putCustomersCustomeridModelselections(customerId: string, rHIMAPOReportingWearManagementApiV1ModelsDataScienceModelSelectionDto?: Array<RHIMAPOReportingWearManagementApiV1ModelsDataScienceModelSelectionDto>, options?: AxiosRequestConfig) {
        return DataScienceModelSelectionsApiFp(this.configuration).putCustomersCustomeridModelselections(customerId, rHIMAPOReportingWearManagementApiV1ModelsDataScienceModelSelectionDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * HostingModesApi - axios parameter creator
 * @export
 */
export const HostingModesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get all available hosting modes.
         * @summary Get all available hosting modes.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHostingmodes: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/HostingModes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * HostingModesApi - functional programming interface
 * @export
 */
export const HostingModesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = HostingModesApiAxiosParamCreator(configuration)
    return {
        /**
         * Get all available hosting modes.
         * @summary Get all available hosting modes.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getHostingmodes(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RHIMAPOCommonDataReportingHostingMode>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getHostingmodes(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * HostingModesApi - factory interface
 * @export
 */
export const HostingModesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = HostingModesApiFp(configuration)
    return {
        /**
         * Get all available hosting modes.
         * @summary Get all available hosting modes.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHostingmodes(options?: any): AxiosPromise<Array<RHIMAPOCommonDataReportingHostingMode>> {
            return localVarFp.getHostingmodes(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * HostingModesApi - object-oriented interface
 * @export
 * @class HostingModesApi
 * @extends {BaseAPI}
 */
export class HostingModesApi extends BaseAPI {
    /**
     * Get all available hosting modes.
     * @summary Get all available hosting modes.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HostingModesApi
     */
    public getHostingmodes(options?: AxiosRequestConfig) {
        return HostingModesApiFp(this.configuration).getHostingmodes(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * OrientationLabelApi - axios parameter creator
 * @export
 */
export const OrientationLabelApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Deletes the orientation label for a customer and a specific vesseltype
         * @summary Deletes the orientation label for a customer and a specific vesseltype
         * @param {string} customerId Format - uuid. The Id from the customer from which the orientation label should be deleted
         * @param {string} vesseltype The vesseltype from which the orientation labels should be deleted
         * @param {number} [degree] Format - int32. Optional query paramater. If it is provided only the entry for the provideed degree will be deleted
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOrientationlabelCustomeridVesseltype: async (customerId: string, vesseltype: string, degree?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('deleteOrientationlabelCustomeridVesseltype', 'customerId', customerId)
            // verify required parameter 'vesseltype' is not null or undefined
            assertParamExists('deleteOrientationlabelCustomeridVesseltype', 'vesseltype', vesseltype)
            const localVarPath = `/OrientationLabel/{customerId}/{vesseltype}`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)))
                .replace(`{${"vesseltype"}}`, encodeURIComponent(String(vesseltype)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (degree !== undefined) {
                localVarQueryParameter['degree'] = degree;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves all orientation labels for a customer and a specific vesseltype
         * @summary Retrieves all orientation labels for a customer and a specific vesseltype
         * @param {string} customerId Format - uuid. The Id from the customer from which the orientation labels should be retrieved
         * @param {string} vesseltype The vesseltype from which the orientation labels should be retrieved
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrientationlabelCustomeridVesseltype: async (customerId: string, vesseltype: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('getOrientationlabelCustomeridVesseltype', 'customerId', customerId)
            // verify required parameter 'vesseltype' is not null or undefined
            assertParamExists('getOrientationlabelCustomeridVesseltype', 'vesseltype', vesseltype)
            const localVarPath = `/OrientationLabel/{customerId}/{vesseltype}`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)))
                .replace(`{${"vesseltype"}}`, encodeURIComponent(String(vesseltype)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Upserts the new orientation labels for a customer. If there was an old orientation label for the customer with the provided vesseltype it will be deleted before the new one is created.
         * @summary Upserts the new orientation labels for a customer. If there was an old orientation label for the customer with the provided vesseltype it will be deleted before the new one is created.
         * @param {string} customerId Format - uuid.
         * @param {RHIMAPOReportingWearManagementApiV1ModelsOrientationLabelUpdateDto} [rHIMAPOReportingWearManagementApiV1ModelsOrientationLabelUpdateDto] Contains the information for the new orientatioLabel
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putOrientationlabelCustomerid: async (customerId: string, rHIMAPOReportingWearManagementApiV1ModelsOrientationLabelUpdateDto?: RHIMAPOReportingWearManagementApiV1ModelsOrientationLabelUpdateDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('putOrientationlabelCustomerid', 'customerId', customerId)
            const localVarPath = `/OrientationLabel/{customerId}`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(rHIMAPOReportingWearManagementApiV1ModelsOrientationLabelUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrientationLabelApi - functional programming interface
 * @export
 */
export const OrientationLabelApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OrientationLabelApiAxiosParamCreator(configuration)
    return {
        /**
         * Deletes the orientation label for a customer and a specific vesseltype
         * @summary Deletes the orientation label for a customer and a specific vesseltype
         * @param {string} customerId Format - uuid. The Id from the customer from which the orientation label should be deleted
         * @param {string} vesseltype The vesseltype from which the orientation labels should be deleted
         * @param {number} [degree] Format - int32. Optional query paramater. If it is provided only the entry for the provideed degree will be deleted
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteOrientationlabelCustomeridVesseltype(customerId: string, vesseltype: string, degree?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteOrientationlabelCustomeridVesseltype(customerId, vesseltype, degree, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves all orientation labels for a customer and a specific vesseltype
         * @summary Retrieves all orientation labels for a customer and a specific vesseltype
         * @param {string} customerId Format - uuid. The Id from the customer from which the orientation labels should be retrieved
         * @param {string} vesseltype The vesseltype from which the orientation labels should be retrieved
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrientationlabelCustomeridVesseltype(customerId: string, vesseltype: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RHIMAPOReportingWearManagementApiV1ModelsOrientationLabelDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOrientationlabelCustomeridVesseltype(customerId, vesseltype, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Upserts the new orientation labels for a customer. If there was an old orientation label for the customer with the provided vesseltype it will be deleted before the new one is created.
         * @summary Upserts the new orientation labels for a customer. If there was an old orientation label for the customer with the provided vesseltype it will be deleted before the new one is created.
         * @param {string} customerId Format - uuid.
         * @param {RHIMAPOReportingWearManagementApiV1ModelsOrientationLabelUpdateDto} [rHIMAPOReportingWearManagementApiV1ModelsOrientationLabelUpdateDto] Contains the information for the new orientatioLabel
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putOrientationlabelCustomerid(customerId: string, rHIMAPOReportingWearManagementApiV1ModelsOrientationLabelUpdateDto?: RHIMAPOReportingWearManagementApiV1ModelsOrientationLabelUpdateDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putOrientationlabelCustomerid(customerId, rHIMAPOReportingWearManagementApiV1ModelsOrientationLabelUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OrientationLabelApi - factory interface
 * @export
 */
export const OrientationLabelApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OrientationLabelApiFp(configuration)
    return {
        /**
         * Deletes the orientation label for a customer and a specific vesseltype
         * @summary Deletes the orientation label for a customer and a specific vesseltype
         * @param {string} customerId Format - uuid. The Id from the customer from which the orientation label should be deleted
         * @param {string} vesseltype The vesseltype from which the orientation labels should be deleted
         * @param {number} [degree] Format - int32. Optional query paramater. If it is provided only the entry for the provideed degree will be deleted
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOrientationlabelCustomeridVesseltype(customerId: string, vesseltype: string, degree?: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteOrientationlabelCustomeridVesseltype(customerId, vesseltype, degree, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves all orientation labels for a customer and a specific vesseltype
         * @summary Retrieves all orientation labels for a customer and a specific vesseltype
         * @param {string} customerId Format - uuid. The Id from the customer from which the orientation labels should be retrieved
         * @param {string} vesseltype The vesseltype from which the orientation labels should be retrieved
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrientationlabelCustomeridVesseltype(customerId: string, vesseltype: string, options?: any): AxiosPromise<Array<RHIMAPOReportingWearManagementApiV1ModelsOrientationLabelDto>> {
            return localVarFp.getOrientationlabelCustomeridVesseltype(customerId, vesseltype, options).then((request) => request(axios, basePath));
        },
        /**
         * Upserts the new orientation labels for a customer. If there was an old orientation label for the customer with the provided vesseltype it will be deleted before the new one is created.
         * @summary Upserts the new orientation labels for a customer. If there was an old orientation label for the customer with the provided vesseltype it will be deleted before the new one is created.
         * @param {string} customerId Format - uuid.
         * @param {RHIMAPOReportingWearManagementApiV1ModelsOrientationLabelUpdateDto} [rHIMAPOReportingWearManagementApiV1ModelsOrientationLabelUpdateDto] Contains the information for the new orientatioLabel
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putOrientationlabelCustomerid(customerId: string, rHIMAPOReportingWearManagementApiV1ModelsOrientationLabelUpdateDto?: RHIMAPOReportingWearManagementApiV1ModelsOrientationLabelUpdateDto, options?: any): AxiosPromise<void> {
            return localVarFp.putOrientationlabelCustomerid(customerId, rHIMAPOReportingWearManagementApiV1ModelsOrientationLabelUpdateDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OrientationLabelApi - object-oriented interface
 * @export
 * @class OrientationLabelApi
 * @extends {BaseAPI}
 */
export class OrientationLabelApi extends BaseAPI {
    /**
     * Deletes the orientation label for a customer and a specific vesseltype
     * @summary Deletes the orientation label for a customer and a specific vesseltype
     * @param {string} customerId Format - uuid. The Id from the customer from which the orientation label should be deleted
     * @param {string} vesseltype The vesseltype from which the orientation labels should be deleted
     * @param {number} [degree] Format - int32. Optional query paramater. If it is provided only the entry for the provideed degree will be deleted
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrientationLabelApi
     */
    public deleteOrientationlabelCustomeridVesseltype(customerId: string, vesseltype: string, degree?: number, options?: AxiosRequestConfig) {
        return OrientationLabelApiFp(this.configuration).deleteOrientationlabelCustomeridVesseltype(customerId, vesseltype, degree, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves all orientation labels for a customer and a specific vesseltype
     * @summary Retrieves all orientation labels for a customer and a specific vesseltype
     * @param {string} customerId Format - uuid. The Id from the customer from which the orientation labels should be retrieved
     * @param {string} vesseltype The vesseltype from which the orientation labels should be retrieved
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrientationLabelApi
     */
    public getOrientationlabelCustomeridVesseltype(customerId: string, vesseltype: string, options?: AxiosRequestConfig) {
        return OrientationLabelApiFp(this.configuration).getOrientationlabelCustomeridVesseltype(customerId, vesseltype, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Upserts the new orientation labels for a customer. If there was an old orientation label for the customer with the provided vesseltype it will be deleted before the new one is created.
     * @summary Upserts the new orientation labels for a customer. If there was an old orientation label for the customer with the provided vesseltype it will be deleted before the new one is created.
     * @param {string} customerId Format - uuid.
     * @param {RHIMAPOReportingWearManagementApiV1ModelsOrientationLabelUpdateDto} [rHIMAPOReportingWearManagementApiV1ModelsOrientationLabelUpdateDto] Contains the information for the new orientatioLabel
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrientationLabelApi
     */
    public putOrientationlabelCustomerid(customerId: string, rHIMAPOReportingWearManagementApiV1ModelsOrientationLabelUpdateDto?: RHIMAPOReportingWearManagementApiV1ModelsOrientationLabelUpdateDto, options?: AxiosRequestConfig) {
        return OrientationLabelApiFp(this.configuration).putOrientationlabelCustomerid(customerId, rHIMAPOReportingWearManagementApiV1ModelsOrientationLabelUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * RegionsApi - axios parameter creator
 * @export
 */
export const RegionsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Delete a region by id
         * @summary Delete a region by id
         * @param {string} regionId Format - uuid. Identifier of the region
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRegionsRegionid: async (regionId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'regionId' is not null or undefined
            assertParamExists('deleteRegionsRegionid', 'regionId', regionId)
            const localVarPath = `/Regions/{regionId}`
                .replace(`{${"regionId"}}`, encodeURIComponent(String(regionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves all regions
         * @summary Retrieves all regions
         * @param {string} [vesselId] Format - uuid. Id of the vessel for which the regions should be retrieved (optional)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRegions: async (vesselId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Regions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (vesselId !== undefined) {
                localVarQueryParameter['vesselId'] = vesselId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a region by its id
         * @summary Retrieves a region by its id
         * @param {string} regionId Format - uuid. Id of the region to be retrieved
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRegionsRegionid: async (regionId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'regionId' is not null or undefined
            assertParamExists('getRegionsRegionid', 'regionId', regionId)
            const localVarPath = `/Regions/{regionId}`
                .replace(`{${"regionId"}}`, encodeURIComponent(String(regionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a new region
         * @summary Creates a new region
         * @param {RHIMAPOReportingWearManagementApiV1ModelsRegionCreateRequestDto} [rHIMAPOReportingWearManagementApiV1ModelsRegionCreateRequestDto] The json body which contains info about the new region.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postRegions: async (rHIMAPOReportingWearManagementApiV1ModelsRegionCreateRequestDto?: RHIMAPOReportingWearManagementApiV1ModelsRegionCreateRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Regions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(rHIMAPOReportingWearManagementApiV1ModelsRegionCreateRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates a region by its id
         * @summary Updates a region by its id
         * @param {string} regionId Format - uuid. Identifier of the region
         * @param {PutRegionsRegionidRequest} [putRegionsRegionidRequest] The updated region
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putRegionsRegionid: async (regionId: string, putRegionsRegionidRequest?: PutRegionsRegionidRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'regionId' is not null or undefined
            assertParamExists('putRegionsRegionid', 'regionId', regionId)
            const localVarPath = `/Regions/{regionId}`
                .replace(`{${"regionId"}}`, encodeURIComponent(String(regionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(putRegionsRegionidRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RegionsApi - functional programming interface
 * @export
 */
export const RegionsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RegionsApiAxiosParamCreator(configuration)
    return {
        /**
         * Delete a region by id
         * @summary Delete a region by id
         * @param {string} regionId Format - uuid. Identifier of the region
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteRegionsRegionid(regionId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteRegionsRegionid(regionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves all regions
         * @summary Retrieves all regions
         * @param {string} [vesselId] Format - uuid. Id of the vessel for which the regions should be retrieved (optional)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRegions(vesselId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RHIMAPOReportingWearManagementApiV1ModelsRegionDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRegions(vesselId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves a region by its id
         * @summary Retrieves a region by its id
         * @param {string} regionId Format - uuid. Id of the region to be retrieved
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRegionsRegionid(regionId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RHIMAPOReportingWearManagementApiV1ModelsRegionDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRegionsRegionid(regionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Creates a new region
         * @summary Creates a new region
         * @param {RHIMAPOReportingWearManagementApiV1ModelsRegionCreateRequestDto} [rHIMAPOReportingWearManagementApiV1ModelsRegionCreateRequestDto] The json body which contains info about the new region.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postRegions(rHIMAPOReportingWearManagementApiV1ModelsRegionCreateRequestDto?: RHIMAPOReportingWearManagementApiV1ModelsRegionCreateRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RHIMAPOReportingWearManagementApiV1ModelsRegionDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postRegions(rHIMAPOReportingWearManagementApiV1ModelsRegionCreateRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates a region by its id
         * @summary Updates a region by its id
         * @param {string} regionId Format - uuid. Identifier of the region
         * @param {PutRegionsRegionidRequest} [putRegionsRegionidRequest] The updated region
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putRegionsRegionid(regionId: string, putRegionsRegionidRequest?: PutRegionsRegionidRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putRegionsRegionid(regionId, putRegionsRegionidRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RegionsApi - factory interface
 * @export
 */
export const RegionsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RegionsApiFp(configuration)
    return {
        /**
         * Delete a region by id
         * @summary Delete a region by id
         * @param {string} regionId Format - uuid. Identifier of the region
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRegionsRegionid(regionId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteRegionsRegionid(regionId, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves all regions
         * @summary Retrieves all regions
         * @param {string} [vesselId] Format - uuid. Id of the vessel for which the regions should be retrieved (optional)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRegions(vesselId?: string, options?: any): AxiosPromise<Array<RHIMAPOReportingWearManagementApiV1ModelsRegionDto>> {
            return localVarFp.getRegions(vesselId, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a region by its id
         * @summary Retrieves a region by its id
         * @param {string} regionId Format - uuid. Id of the region to be retrieved
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRegionsRegionid(regionId: string, options?: any): AxiosPromise<RHIMAPOReportingWearManagementApiV1ModelsRegionDto> {
            return localVarFp.getRegionsRegionid(regionId, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a new region
         * @summary Creates a new region
         * @param {RHIMAPOReportingWearManagementApiV1ModelsRegionCreateRequestDto} [rHIMAPOReportingWearManagementApiV1ModelsRegionCreateRequestDto] The json body which contains info about the new region.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postRegions(rHIMAPOReportingWearManagementApiV1ModelsRegionCreateRequestDto?: RHIMAPOReportingWearManagementApiV1ModelsRegionCreateRequestDto, options?: any): AxiosPromise<RHIMAPOReportingWearManagementApiV1ModelsRegionDto> {
            return localVarFp.postRegions(rHIMAPOReportingWearManagementApiV1ModelsRegionCreateRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates a region by its id
         * @summary Updates a region by its id
         * @param {string} regionId Format - uuid. Identifier of the region
         * @param {PutRegionsRegionidRequest} [putRegionsRegionidRequest] The updated region
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putRegionsRegionid(regionId: string, putRegionsRegionidRequest?: PutRegionsRegionidRequest, options?: any): AxiosPromise<void> {
            return localVarFp.putRegionsRegionid(regionId, putRegionsRegionidRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RegionsApi - object-oriented interface
 * @export
 * @class RegionsApi
 * @extends {BaseAPI}
 */
export class RegionsApi extends BaseAPI {
    /**
     * Delete a region by id
     * @summary Delete a region by id
     * @param {string} regionId Format - uuid. Identifier of the region
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegionsApi
     */
    public deleteRegionsRegionid(regionId: string, options?: AxiosRequestConfig) {
        return RegionsApiFp(this.configuration).deleteRegionsRegionid(regionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves all regions
     * @summary Retrieves all regions
     * @param {string} [vesselId] Format - uuid. Id of the vessel for which the regions should be retrieved (optional)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegionsApi
     */
    public getRegions(vesselId?: string, options?: AxiosRequestConfig) {
        return RegionsApiFp(this.configuration).getRegions(vesselId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a region by its id
     * @summary Retrieves a region by its id
     * @param {string} regionId Format - uuid. Id of the region to be retrieved
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegionsApi
     */
    public getRegionsRegionid(regionId: string, options?: AxiosRequestConfig) {
        return RegionsApiFp(this.configuration).getRegionsRegionid(regionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a new region
     * @summary Creates a new region
     * @param {RHIMAPOReportingWearManagementApiV1ModelsRegionCreateRequestDto} [rHIMAPOReportingWearManagementApiV1ModelsRegionCreateRequestDto] The json body which contains info about the new region.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegionsApi
     */
    public postRegions(rHIMAPOReportingWearManagementApiV1ModelsRegionCreateRequestDto?: RHIMAPOReportingWearManagementApiV1ModelsRegionCreateRequestDto, options?: AxiosRequestConfig) {
        return RegionsApiFp(this.configuration).postRegions(rHIMAPOReportingWearManagementApiV1ModelsRegionCreateRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates a region by its id
     * @summary Updates a region by its id
     * @param {string} regionId Format - uuid. Identifier of the region
     * @param {PutRegionsRegionidRequest} [putRegionsRegionidRequest] The updated region
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegionsApi
     */
    public putRegionsRegionid(regionId: string, putRegionsRegionidRequest?: PutRegionsRegionidRequest, options?: AxiosRequestConfig) {
        return RegionsApiFp(this.configuration).putRegionsRegionid(regionId, putRegionsRegionidRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * VesselFunctionalProductsApi - axios parameter creator
 * @export
 */
export const VesselFunctionalProductsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Deletes a functional product
         * @summary Deletes a functional product
         * @param {string} vesselFunctionalProductId Format - uuid. The ID from the functional product to delete.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteVesselfunctionalproductsVesselfunctionalproductid: async (vesselFunctionalProductId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'vesselFunctionalProductId' is not null or undefined
            assertParamExists('deleteVesselfunctionalproductsVesselfunctionalproductid', 'vesselFunctionalProductId', vesselFunctionalProductId)
            const localVarPath = `/VesselFunctionalProducts/{vesselFunctionalProductId}`
                .replace(`{${"vesselFunctionalProductId"}}`, encodeURIComponent(String(vesselFunctionalProductId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves all functional products
         * @summary Retrieves all functional products
         * @param {string} [vesselId] Format - uuid. Id of the vessel for which the functional products should be retrieved
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVesselfunctionalproducts: async (vesselId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/VesselFunctionalProducts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (vesselId !== undefined) {
                localVarQueryParameter['vesselId'] = vesselId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves all functional products for a vessel
         * @summary Retrieves all functional products for a vessel
         * @param {string} vesselId Format - uuid. Id of the vessel for which the functional products should be retrieved
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVesselfunctionalproductsVesselid: async (vesselId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'vesselId' is not null or undefined
            assertParamExists('getVesselfunctionalproductsVesselid', 'vesselId', vesselId)
            const localVarPath = `/VesselFunctionalProducts/{vesselId}`
                .replace(`{${"vesselId"}}`, encodeURIComponent(String(vesselId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a new functional product
         * @summary Creates a new functional product
         * @param {RHIMAPOReportingWearManagementApiV1ModelsVesselFunctionalProductCreateRequestDto} [rHIMAPOReportingWearManagementApiV1ModelsVesselFunctionalProductCreateRequestDto] The json body which contains info about the functional product.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postVesselfunctionalproducts: async (rHIMAPOReportingWearManagementApiV1ModelsVesselFunctionalProductCreateRequestDto?: RHIMAPOReportingWearManagementApiV1ModelsVesselFunctionalProductCreateRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/VesselFunctionalProducts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(rHIMAPOReportingWearManagementApiV1ModelsVesselFunctionalProductCreateRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates a functional product
         * @summary Updates a functional product
         * @param {string} vesselFunctionalProductId Format - uuid. The ID from the functional product to update.
         * @param {RHIMAPOReportingWearManagementApiV1ModelsVesselFunctionalProductUpdateRequestDto} [rHIMAPOReportingWearManagementApiV1ModelsVesselFunctionalProductUpdateRequestDto] The json body which contains info about the functional product.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putVesselfunctionalproductsVesselfunctionalproductid: async (vesselFunctionalProductId: string, rHIMAPOReportingWearManagementApiV1ModelsVesselFunctionalProductUpdateRequestDto?: RHIMAPOReportingWearManagementApiV1ModelsVesselFunctionalProductUpdateRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'vesselFunctionalProductId' is not null or undefined
            assertParamExists('putVesselfunctionalproductsVesselfunctionalproductid', 'vesselFunctionalProductId', vesselFunctionalProductId)
            const localVarPath = `/VesselFunctionalProducts/{vesselFunctionalProductId}`
                .replace(`{${"vesselFunctionalProductId"}}`, encodeURIComponent(String(vesselFunctionalProductId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(rHIMAPOReportingWearManagementApiV1ModelsVesselFunctionalProductUpdateRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * VesselFunctionalProductsApi - functional programming interface
 * @export
 */
export const VesselFunctionalProductsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = VesselFunctionalProductsApiAxiosParamCreator(configuration)
    return {
        /**
         * Deletes a functional product
         * @summary Deletes a functional product
         * @param {string} vesselFunctionalProductId Format - uuid. The ID from the functional product to delete.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteVesselfunctionalproductsVesselfunctionalproductid(vesselFunctionalProductId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteVesselfunctionalproductsVesselfunctionalproductid(vesselFunctionalProductId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves all functional products
         * @summary Retrieves all functional products
         * @param {string} [vesselId] Format - uuid. Id of the vessel for which the functional products should be retrieved
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getVesselfunctionalproducts(vesselId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RHIMAPOReportingWearManagementApiV1ModelsVesselFunctionalProductDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getVesselfunctionalproducts(vesselId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves all functional products for a vessel
         * @summary Retrieves all functional products for a vessel
         * @param {string} vesselId Format - uuid. Id of the vessel for which the functional products should be retrieved
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getVesselfunctionalproductsVesselid(vesselId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RHIMAPOReportingWearManagementApiV1ModelsVesselFunctionalProductDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getVesselfunctionalproductsVesselid(vesselId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Creates a new functional product
         * @summary Creates a new functional product
         * @param {RHIMAPOReportingWearManagementApiV1ModelsVesselFunctionalProductCreateRequestDto} [rHIMAPOReportingWearManagementApiV1ModelsVesselFunctionalProductCreateRequestDto] The json body which contains info about the functional product.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postVesselfunctionalproducts(rHIMAPOReportingWearManagementApiV1ModelsVesselFunctionalProductCreateRequestDto?: RHIMAPOReportingWearManagementApiV1ModelsVesselFunctionalProductCreateRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RHIMAPOReportingWearManagementApiV1ModelsVesselFunctionalProductDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postVesselfunctionalproducts(rHIMAPOReportingWearManagementApiV1ModelsVesselFunctionalProductCreateRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates a functional product
         * @summary Updates a functional product
         * @param {string} vesselFunctionalProductId Format - uuid. The ID from the functional product to update.
         * @param {RHIMAPOReportingWearManagementApiV1ModelsVesselFunctionalProductUpdateRequestDto} [rHIMAPOReportingWearManagementApiV1ModelsVesselFunctionalProductUpdateRequestDto] The json body which contains info about the functional product.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putVesselfunctionalproductsVesselfunctionalproductid(vesselFunctionalProductId: string, rHIMAPOReportingWearManagementApiV1ModelsVesselFunctionalProductUpdateRequestDto?: RHIMAPOReportingWearManagementApiV1ModelsVesselFunctionalProductUpdateRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putVesselfunctionalproductsVesselfunctionalproductid(vesselFunctionalProductId, rHIMAPOReportingWearManagementApiV1ModelsVesselFunctionalProductUpdateRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * VesselFunctionalProductsApi - factory interface
 * @export
 */
export const VesselFunctionalProductsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = VesselFunctionalProductsApiFp(configuration)
    return {
        /**
         * Deletes a functional product
         * @summary Deletes a functional product
         * @param {string} vesselFunctionalProductId Format - uuid. The ID from the functional product to delete.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteVesselfunctionalproductsVesselfunctionalproductid(vesselFunctionalProductId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteVesselfunctionalproductsVesselfunctionalproductid(vesselFunctionalProductId, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves all functional products
         * @summary Retrieves all functional products
         * @param {string} [vesselId] Format - uuid. Id of the vessel for which the functional products should be retrieved
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVesselfunctionalproducts(vesselId?: string, options?: any): AxiosPromise<Array<RHIMAPOReportingWearManagementApiV1ModelsVesselFunctionalProductDto>> {
            return localVarFp.getVesselfunctionalproducts(vesselId, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves all functional products for a vessel
         * @summary Retrieves all functional products for a vessel
         * @param {string} vesselId Format - uuid. Id of the vessel for which the functional products should be retrieved
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVesselfunctionalproductsVesselid(vesselId: string, options?: any): AxiosPromise<Array<RHIMAPOReportingWearManagementApiV1ModelsVesselFunctionalProductDto>> {
            return localVarFp.getVesselfunctionalproductsVesselid(vesselId, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a new functional product
         * @summary Creates a new functional product
         * @param {RHIMAPOReportingWearManagementApiV1ModelsVesselFunctionalProductCreateRequestDto} [rHIMAPOReportingWearManagementApiV1ModelsVesselFunctionalProductCreateRequestDto] The json body which contains info about the functional product.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postVesselfunctionalproducts(rHIMAPOReportingWearManagementApiV1ModelsVesselFunctionalProductCreateRequestDto?: RHIMAPOReportingWearManagementApiV1ModelsVesselFunctionalProductCreateRequestDto, options?: any): AxiosPromise<RHIMAPOReportingWearManagementApiV1ModelsVesselFunctionalProductDto> {
            return localVarFp.postVesselfunctionalproducts(rHIMAPOReportingWearManagementApiV1ModelsVesselFunctionalProductCreateRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates a functional product
         * @summary Updates a functional product
         * @param {string} vesselFunctionalProductId Format - uuid. The ID from the functional product to update.
         * @param {RHIMAPOReportingWearManagementApiV1ModelsVesselFunctionalProductUpdateRequestDto} [rHIMAPOReportingWearManagementApiV1ModelsVesselFunctionalProductUpdateRequestDto] The json body which contains info about the functional product.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putVesselfunctionalproductsVesselfunctionalproductid(vesselFunctionalProductId: string, rHIMAPOReportingWearManagementApiV1ModelsVesselFunctionalProductUpdateRequestDto?: RHIMAPOReportingWearManagementApiV1ModelsVesselFunctionalProductUpdateRequestDto, options?: any): AxiosPromise<void> {
            return localVarFp.putVesselfunctionalproductsVesselfunctionalproductid(vesselFunctionalProductId, rHIMAPOReportingWearManagementApiV1ModelsVesselFunctionalProductUpdateRequestDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * VesselFunctionalProductsApi - object-oriented interface
 * @export
 * @class VesselFunctionalProductsApi
 * @extends {BaseAPI}
 */
export class VesselFunctionalProductsApi extends BaseAPI {
    /**
     * Deletes a functional product
     * @summary Deletes a functional product
     * @param {string} vesselFunctionalProductId Format - uuid. The ID from the functional product to delete.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VesselFunctionalProductsApi
     */
    public deleteVesselfunctionalproductsVesselfunctionalproductid(vesselFunctionalProductId: string, options?: AxiosRequestConfig) {
        return VesselFunctionalProductsApiFp(this.configuration).deleteVesselfunctionalproductsVesselfunctionalproductid(vesselFunctionalProductId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves all functional products
     * @summary Retrieves all functional products
     * @param {string} [vesselId] Format - uuid. Id of the vessel for which the functional products should be retrieved
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VesselFunctionalProductsApi
     */
    public getVesselfunctionalproducts(vesselId?: string, options?: AxiosRequestConfig) {
        return VesselFunctionalProductsApiFp(this.configuration).getVesselfunctionalproducts(vesselId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves all functional products for a vessel
     * @summary Retrieves all functional products for a vessel
     * @param {string} vesselId Format - uuid. Id of the vessel for which the functional products should be retrieved
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VesselFunctionalProductsApi
     */
    public getVesselfunctionalproductsVesselid(vesselId: string, options?: AxiosRequestConfig) {
        return VesselFunctionalProductsApiFp(this.configuration).getVesselfunctionalproductsVesselid(vesselId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a new functional product
     * @summary Creates a new functional product
     * @param {RHIMAPOReportingWearManagementApiV1ModelsVesselFunctionalProductCreateRequestDto} [rHIMAPOReportingWearManagementApiV1ModelsVesselFunctionalProductCreateRequestDto] The json body which contains info about the functional product.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VesselFunctionalProductsApi
     */
    public postVesselfunctionalproducts(rHIMAPOReportingWearManagementApiV1ModelsVesselFunctionalProductCreateRequestDto?: RHIMAPOReportingWearManagementApiV1ModelsVesselFunctionalProductCreateRequestDto, options?: AxiosRequestConfig) {
        return VesselFunctionalProductsApiFp(this.configuration).postVesselfunctionalproducts(rHIMAPOReportingWearManagementApiV1ModelsVesselFunctionalProductCreateRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates a functional product
     * @summary Updates a functional product
     * @param {string} vesselFunctionalProductId Format - uuid. The ID from the functional product to update.
     * @param {RHIMAPOReportingWearManagementApiV1ModelsVesselFunctionalProductUpdateRequestDto} [rHIMAPOReportingWearManagementApiV1ModelsVesselFunctionalProductUpdateRequestDto] The json body which contains info about the functional product.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VesselFunctionalProductsApi
     */
    public putVesselfunctionalproductsVesselfunctionalproductid(vesselFunctionalProductId: string, rHIMAPOReportingWearManagementApiV1ModelsVesselFunctionalProductUpdateRequestDto?: RHIMAPOReportingWearManagementApiV1ModelsVesselFunctionalProductUpdateRequestDto, options?: AxiosRequestConfig) {
        return VesselFunctionalProductsApiFp(this.configuration).putVesselfunctionalproductsVesselfunctionalproductid(vesselFunctionalProductId, rHIMAPOReportingWearManagementApiV1ModelsVesselFunctionalProductUpdateRequestDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * WearExplorerViewApi - axios parameter creator
 * @export
 */
export const WearExplorerViewApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Gets the latest BOF prediction json for a given set of parameters and maps it to a defined DTO.  Caution: This returns a huge object, swagger likely can\'t handle this because it tries to prettify output so consider using postman or directly the api in browser for testing
         * @summary Gets the latest BOF prediction json for a given set of parameters and maps it to a defined DTO.  Caution: This returns a huge object, swagger likely can\'t handle this because it tries to prettify output so consider using postman or directly the api in browser for testing
         * @param {string} vesselId Format - uuid. The ID of the vessel
         * @param {string} [campaign] The campaign of the prediction. (This parameter is optional, if omitted the latest prediction will be taken)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWearexplorerviewVesselsBofVesselid: async (vesselId: string, campaign?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'vesselId' is not null or undefined
            assertParamExists('getWearexplorerviewVesselsBofVesselid', 'vesselId', vesselId)
            const localVarPath = `/WearExplorerView/vessels/bof/{vesselId}`
                .replace(`{${"vesselId"}}`, encodeURIComponent(String(vesselId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (campaign !== undefined) {
                localVarQueryParameter['campaign'] = campaign;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets the latest RH prediction json for a given set of parameters and maps it to a defined DTO.  Caution: This returns a huge object, swagger likely can\'t handle this because it tries to prettify output so consider using postman or directly the api in browser for testing
         * @summary Gets the latest RH prediction json for a given set of parameters and maps it to a defined DTO.  Caution: This returns a huge object, swagger likely can\'t handle this because it tries to prettify output so consider using postman or directly the api in browser for testing
         * @param {string} vesselId Format - uuid. The ID of the vessel
         * @param {string} [campaign] The campaign of the prediction. (This parameter is optional, if omitted the latest prediction will be taken)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWearexplorerviewVesselsRhVesselid: async (vesselId: string, campaign?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'vesselId' is not null or undefined
            assertParamExists('getWearexplorerviewVesselsRhVesselid', 'vesselId', vesselId)
            const localVarPath = `/WearExplorerView/vessels/rh/{vesselId}`
                .replace(`{${"vesselId"}}`, encodeURIComponent(String(vesselId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (campaign !== undefined) {
                localVarQueryParameter['campaign'] = campaign;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WearExplorerViewApi - functional programming interface
 * @export
 */
export const WearExplorerViewApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WearExplorerViewApiAxiosParamCreator(configuration)
    return {
        /**
         * Gets the latest BOF prediction json for a given set of parameters and maps it to a defined DTO.  Caution: This returns a huge object, swagger likely can\'t handle this because it tries to prettify output so consider using postman or directly the api in browser for testing
         * @summary Gets the latest BOF prediction json for a given set of parameters and maps it to a defined DTO.  Caution: This returns a huge object, swagger likely can\'t handle this because it tries to prettify output so consider using postman or directly the api in browser for testing
         * @param {string} vesselId Format - uuid. The ID of the vessel
         * @param {string} [campaign] The campaign of the prediction. (This parameter is optional, if omitted the latest prediction will be taken)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWearexplorerviewVesselsBofVesselid(vesselId: string, campaign?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RHIMAPOReportingWearManagementApiV1ModelsBOFBofReportModelDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWearexplorerviewVesselsBofVesselid(vesselId, campaign, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Gets the latest RH prediction json for a given set of parameters and maps it to a defined DTO.  Caution: This returns a huge object, swagger likely can\'t handle this because it tries to prettify output so consider using postman or directly the api in browser for testing
         * @summary Gets the latest RH prediction json for a given set of parameters and maps it to a defined DTO.  Caution: This returns a huge object, swagger likely can\'t handle this because it tries to prettify output so consider using postman or directly the api in browser for testing
         * @param {string} vesselId Format - uuid. The ID of the vessel
         * @param {string} [campaign] The campaign of the prediction. (This parameter is optional, if omitted the latest prediction will be taken)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWearexplorerviewVesselsRhVesselid(vesselId: string, campaign?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RHIMAPOReportingWearManagementApiV1ModelsRHRhReportModelDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWearexplorerviewVesselsRhVesselid(vesselId, campaign, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * WearExplorerViewApi - factory interface
 * @export
 */
export const WearExplorerViewApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WearExplorerViewApiFp(configuration)
    return {
        /**
         * Gets the latest BOF prediction json for a given set of parameters and maps it to a defined DTO.  Caution: This returns a huge object, swagger likely can\'t handle this because it tries to prettify output so consider using postman or directly the api in browser for testing
         * @summary Gets the latest BOF prediction json for a given set of parameters and maps it to a defined DTO.  Caution: This returns a huge object, swagger likely can\'t handle this because it tries to prettify output so consider using postman or directly the api in browser for testing
         * @param {string} vesselId Format - uuid. The ID of the vessel
         * @param {string} [campaign] The campaign of the prediction. (This parameter is optional, if omitted the latest prediction will be taken)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWearexplorerviewVesselsBofVesselid(vesselId: string, campaign?: string, options?: any): AxiosPromise<RHIMAPOReportingWearManagementApiV1ModelsBOFBofReportModelDto> {
            return localVarFp.getWearexplorerviewVesselsBofVesselid(vesselId, campaign, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets the latest RH prediction json for a given set of parameters and maps it to a defined DTO.  Caution: This returns a huge object, swagger likely can\'t handle this because it tries to prettify output so consider using postman or directly the api in browser for testing
         * @summary Gets the latest RH prediction json for a given set of parameters and maps it to a defined DTO.  Caution: This returns a huge object, swagger likely can\'t handle this because it tries to prettify output so consider using postman or directly the api in browser for testing
         * @param {string} vesselId Format - uuid. The ID of the vessel
         * @param {string} [campaign] The campaign of the prediction. (This parameter is optional, if omitted the latest prediction will be taken)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWearexplorerviewVesselsRhVesselid(vesselId: string, campaign?: string, options?: any): AxiosPromise<RHIMAPOReportingWearManagementApiV1ModelsRHRhReportModelDto> {
            return localVarFp.getWearexplorerviewVesselsRhVesselid(vesselId, campaign, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * WearExplorerViewApi - object-oriented interface
 * @export
 * @class WearExplorerViewApi
 * @extends {BaseAPI}
 */
export class WearExplorerViewApi extends BaseAPI {
    /**
     * Gets the latest BOF prediction json for a given set of parameters and maps it to a defined DTO.  Caution: This returns a huge object, swagger likely can\'t handle this because it tries to prettify output so consider using postman or directly the api in browser for testing
     * @summary Gets the latest BOF prediction json for a given set of parameters and maps it to a defined DTO.  Caution: This returns a huge object, swagger likely can\'t handle this because it tries to prettify output so consider using postman or directly the api in browser for testing
     * @param {string} vesselId Format - uuid. The ID of the vessel
     * @param {string} [campaign] The campaign of the prediction. (This parameter is optional, if omitted the latest prediction will be taken)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WearExplorerViewApi
     */
    public getWearexplorerviewVesselsBofVesselid(vesselId: string, campaign?: string, options?: AxiosRequestConfig) {
        return WearExplorerViewApiFp(this.configuration).getWearexplorerviewVesselsBofVesselid(vesselId, campaign, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets the latest RH prediction json for a given set of parameters and maps it to a defined DTO.  Caution: This returns a huge object, swagger likely can\'t handle this because it tries to prettify output so consider using postman or directly the api in browser for testing
     * @summary Gets the latest RH prediction json for a given set of parameters and maps it to a defined DTO.  Caution: This returns a huge object, swagger likely can\'t handle this because it tries to prettify output so consider using postman or directly the api in browser for testing
     * @param {string} vesselId Format - uuid. The ID of the vessel
     * @param {string} [campaign] The campaign of the prediction. (This parameter is optional, if omitted the latest prediction will be taken)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WearExplorerViewApi
     */
    public getWearexplorerviewVesselsRhVesselid(vesselId: string, campaign?: string, options?: AxiosRequestConfig) {
        return WearExplorerViewApiFp(this.configuration).getWearexplorerviewVesselsRhVesselid(vesselId, campaign, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * WearPredictionScheduleOptionsApi - axios parameter creator
 * @export
 */
export const WearPredictionScheduleOptionsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Delete a wearprediction schedule option by id
         * @summary Delete a wearprediction schedule option by id
         * @param {string} optionId Format - uuid. Identifier of the schedule option
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWearpredictionscheduleoptionsOptionid: async (optionId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'optionId' is not null or undefined
            assertParamExists('deleteWearpredictionscheduleoptionsOptionid', 'optionId', optionId)
            const localVarPath = `/WearPredictionScheduleOptions/{optionId}`
                .replace(`{${"optionId"}}`, encodeURIComponent(String(optionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves all WearPredictionScheduleOptions
         * @summary Retrieves all WearPredictionScheduleOptions
         * @param {string} [customerId] Format - uuid. Id of the customer for which the options should be retrieved (optional)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWearpredictionscheduleoptions: async (customerId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/WearPredictionScheduleOptions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (customerId !== undefined) {
                localVarQueryParameter['customerId'] = customerId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a WearPredictionScheduleOption by its id
         * @summary Retrieves a WearPredictionScheduleOption by its id
         * @param {string} optionId Format - uuid. Id of the schedule option to be retrieved
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWearpredictionscheduleoptionsOptionid: async (optionId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'optionId' is not null or undefined
            assertParamExists('getWearpredictionscheduleoptionsOptionid', 'optionId', optionId)
            const localVarPath = `/WearPredictionScheduleOptions/{optionId}`
                .replace(`{${"optionId"}}`, encodeURIComponent(String(optionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a WearPredictionScheduleOption
         * @summary Creates a WearPredictionScheduleOption
         * @param {RHIMAPOReportingWearManagementApiV1ModelsWearPredictionScheduleOptionRequestDto} [rHIMAPOReportingWearManagementApiV1ModelsWearPredictionScheduleOptionRequestDto] The json body which contains info about the new schedule option.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postWearpredictionscheduleoptions: async (rHIMAPOReportingWearManagementApiV1ModelsWearPredictionScheduleOptionRequestDto?: RHIMAPOReportingWearManagementApiV1ModelsWearPredictionScheduleOptionRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/WearPredictionScheduleOptions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(rHIMAPOReportingWearManagementApiV1ModelsWearPredictionScheduleOptionRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates a WearPredictionScheduleOption by its id
         * @summary Updates a WearPredictionScheduleOption by its id
         * @param {string} optionId Format - uuid. Identifier of the schedule option
         * @param {RHIMAPOReportingWearManagementApiV1ModelsWearPredictionScheduleOptionRequestDto} [rHIMAPOReportingWearManagementApiV1ModelsWearPredictionScheduleOptionRequestDto] The updated schedule option
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putWearpredictionscheduleoptionsOptionid: async (optionId: string, rHIMAPOReportingWearManagementApiV1ModelsWearPredictionScheduleOptionRequestDto?: RHIMAPOReportingWearManagementApiV1ModelsWearPredictionScheduleOptionRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'optionId' is not null or undefined
            assertParamExists('putWearpredictionscheduleoptionsOptionid', 'optionId', optionId)
            const localVarPath = `/WearPredictionScheduleOptions/{optionId}`
                .replace(`{${"optionId"}}`, encodeURIComponent(String(optionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(rHIMAPOReportingWearManagementApiV1ModelsWearPredictionScheduleOptionRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WearPredictionScheduleOptionsApi - functional programming interface
 * @export
 */
export const WearPredictionScheduleOptionsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WearPredictionScheduleOptionsApiAxiosParamCreator(configuration)
    return {
        /**
         * Delete a wearprediction schedule option by id
         * @summary Delete a wearprediction schedule option by id
         * @param {string} optionId Format - uuid. Identifier of the schedule option
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteWearpredictionscheduleoptionsOptionid(optionId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteWearpredictionscheduleoptionsOptionid(optionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves all WearPredictionScheduleOptions
         * @summary Retrieves all WearPredictionScheduleOptions
         * @param {string} [customerId] Format - uuid. Id of the customer for which the options should be retrieved (optional)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWearpredictionscheduleoptions(customerId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RHIMAPOReportingWearManagementApiV1ModelsWearPredictionScheduleOptionDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWearpredictionscheduleoptions(customerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves a WearPredictionScheduleOption by its id
         * @summary Retrieves a WearPredictionScheduleOption by its id
         * @param {string} optionId Format - uuid. Id of the schedule option to be retrieved
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWearpredictionscheduleoptionsOptionid(optionId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RHIMAPOReportingWearManagementApiV1ModelsWearPredictionScheduleOptionDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWearpredictionscheduleoptionsOptionid(optionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Creates a WearPredictionScheduleOption
         * @summary Creates a WearPredictionScheduleOption
         * @param {RHIMAPOReportingWearManagementApiV1ModelsWearPredictionScheduleOptionRequestDto} [rHIMAPOReportingWearManagementApiV1ModelsWearPredictionScheduleOptionRequestDto] The json body which contains info about the new schedule option.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postWearpredictionscheduleoptions(rHIMAPOReportingWearManagementApiV1ModelsWearPredictionScheduleOptionRequestDto?: RHIMAPOReportingWearManagementApiV1ModelsWearPredictionScheduleOptionRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RHIMAPOReportingWearManagementApiV1ModelsWearPredictionScheduleOptionDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postWearpredictionscheduleoptions(rHIMAPOReportingWearManagementApiV1ModelsWearPredictionScheduleOptionRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates a WearPredictionScheduleOption by its id
         * @summary Updates a WearPredictionScheduleOption by its id
         * @param {string} optionId Format - uuid. Identifier of the schedule option
         * @param {RHIMAPOReportingWearManagementApiV1ModelsWearPredictionScheduleOptionRequestDto} [rHIMAPOReportingWearManagementApiV1ModelsWearPredictionScheduleOptionRequestDto] The updated schedule option
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putWearpredictionscheduleoptionsOptionid(optionId: string, rHIMAPOReportingWearManagementApiV1ModelsWearPredictionScheduleOptionRequestDto?: RHIMAPOReportingWearManagementApiV1ModelsWearPredictionScheduleOptionRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putWearpredictionscheduleoptionsOptionid(optionId, rHIMAPOReportingWearManagementApiV1ModelsWearPredictionScheduleOptionRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * WearPredictionScheduleOptionsApi - factory interface
 * @export
 */
export const WearPredictionScheduleOptionsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WearPredictionScheduleOptionsApiFp(configuration)
    return {
        /**
         * Delete a wearprediction schedule option by id
         * @summary Delete a wearprediction schedule option by id
         * @param {string} optionId Format - uuid. Identifier of the schedule option
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWearpredictionscheduleoptionsOptionid(optionId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteWearpredictionscheduleoptionsOptionid(optionId, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves all WearPredictionScheduleOptions
         * @summary Retrieves all WearPredictionScheduleOptions
         * @param {string} [customerId] Format - uuid. Id of the customer for which the options should be retrieved (optional)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWearpredictionscheduleoptions(customerId?: string, options?: any): AxiosPromise<Array<RHIMAPOReportingWearManagementApiV1ModelsWearPredictionScheduleOptionDto>> {
            return localVarFp.getWearpredictionscheduleoptions(customerId, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a WearPredictionScheduleOption by its id
         * @summary Retrieves a WearPredictionScheduleOption by its id
         * @param {string} optionId Format - uuid. Id of the schedule option to be retrieved
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWearpredictionscheduleoptionsOptionid(optionId: string, options?: any): AxiosPromise<RHIMAPOReportingWearManagementApiV1ModelsWearPredictionScheduleOptionDto> {
            return localVarFp.getWearpredictionscheduleoptionsOptionid(optionId, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a WearPredictionScheduleOption
         * @summary Creates a WearPredictionScheduleOption
         * @param {RHIMAPOReportingWearManagementApiV1ModelsWearPredictionScheduleOptionRequestDto} [rHIMAPOReportingWearManagementApiV1ModelsWearPredictionScheduleOptionRequestDto] The json body which contains info about the new schedule option.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postWearpredictionscheduleoptions(rHIMAPOReportingWearManagementApiV1ModelsWearPredictionScheduleOptionRequestDto?: RHIMAPOReportingWearManagementApiV1ModelsWearPredictionScheduleOptionRequestDto, options?: any): AxiosPromise<RHIMAPOReportingWearManagementApiV1ModelsWearPredictionScheduleOptionDto> {
            return localVarFp.postWearpredictionscheduleoptions(rHIMAPOReportingWearManagementApiV1ModelsWearPredictionScheduleOptionRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates a WearPredictionScheduleOption by its id
         * @summary Updates a WearPredictionScheduleOption by its id
         * @param {string} optionId Format - uuid. Identifier of the schedule option
         * @param {RHIMAPOReportingWearManagementApiV1ModelsWearPredictionScheduleOptionRequestDto} [rHIMAPOReportingWearManagementApiV1ModelsWearPredictionScheduleOptionRequestDto] The updated schedule option
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putWearpredictionscheduleoptionsOptionid(optionId: string, rHIMAPOReportingWearManagementApiV1ModelsWearPredictionScheduleOptionRequestDto?: RHIMAPOReportingWearManagementApiV1ModelsWearPredictionScheduleOptionRequestDto, options?: any): AxiosPromise<void> {
            return localVarFp.putWearpredictionscheduleoptionsOptionid(optionId, rHIMAPOReportingWearManagementApiV1ModelsWearPredictionScheduleOptionRequestDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * WearPredictionScheduleOptionsApi - object-oriented interface
 * @export
 * @class WearPredictionScheduleOptionsApi
 * @extends {BaseAPI}
 */
export class WearPredictionScheduleOptionsApi extends BaseAPI {
    /**
     * Delete a wearprediction schedule option by id
     * @summary Delete a wearprediction schedule option by id
     * @param {string} optionId Format - uuid. Identifier of the schedule option
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WearPredictionScheduleOptionsApi
     */
    public deleteWearpredictionscheduleoptionsOptionid(optionId: string, options?: AxiosRequestConfig) {
        return WearPredictionScheduleOptionsApiFp(this.configuration).deleteWearpredictionscheduleoptionsOptionid(optionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves all WearPredictionScheduleOptions
     * @summary Retrieves all WearPredictionScheduleOptions
     * @param {string} [customerId] Format - uuid. Id of the customer for which the options should be retrieved (optional)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WearPredictionScheduleOptionsApi
     */
    public getWearpredictionscheduleoptions(customerId?: string, options?: AxiosRequestConfig) {
        return WearPredictionScheduleOptionsApiFp(this.configuration).getWearpredictionscheduleoptions(customerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a WearPredictionScheduleOption by its id
     * @summary Retrieves a WearPredictionScheduleOption by its id
     * @param {string} optionId Format - uuid. Id of the schedule option to be retrieved
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WearPredictionScheduleOptionsApi
     */
    public getWearpredictionscheduleoptionsOptionid(optionId: string, options?: AxiosRequestConfig) {
        return WearPredictionScheduleOptionsApiFp(this.configuration).getWearpredictionscheduleoptionsOptionid(optionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a WearPredictionScheduleOption
     * @summary Creates a WearPredictionScheduleOption
     * @param {RHIMAPOReportingWearManagementApiV1ModelsWearPredictionScheduleOptionRequestDto} [rHIMAPOReportingWearManagementApiV1ModelsWearPredictionScheduleOptionRequestDto] The json body which contains info about the new schedule option.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WearPredictionScheduleOptionsApi
     */
    public postWearpredictionscheduleoptions(rHIMAPOReportingWearManagementApiV1ModelsWearPredictionScheduleOptionRequestDto?: RHIMAPOReportingWearManagementApiV1ModelsWearPredictionScheduleOptionRequestDto, options?: AxiosRequestConfig) {
        return WearPredictionScheduleOptionsApiFp(this.configuration).postWearpredictionscheduleoptions(rHIMAPOReportingWearManagementApiV1ModelsWearPredictionScheduleOptionRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates a WearPredictionScheduleOption by its id
     * @summary Updates a WearPredictionScheduleOption by its id
     * @param {string} optionId Format - uuid. Identifier of the schedule option
     * @param {RHIMAPOReportingWearManagementApiV1ModelsWearPredictionScheduleOptionRequestDto} [rHIMAPOReportingWearManagementApiV1ModelsWearPredictionScheduleOptionRequestDto] The updated schedule option
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WearPredictionScheduleOptionsApi
     */
    public putWearpredictionscheduleoptionsOptionid(optionId: string, rHIMAPOReportingWearManagementApiV1ModelsWearPredictionScheduleOptionRequestDto?: RHIMAPOReportingWearManagementApiV1ModelsWearPredictionScheduleOptionRequestDto, options?: AxiosRequestConfig) {
        return WearPredictionScheduleOptionsApiFp(this.configuration).putWearpredictionscheduleoptionsOptionid(optionId, rHIMAPOReportingWearManagementApiV1ModelsWearPredictionScheduleOptionRequestDto, options).then((request) => request(this.axios, this.basePath));
    }
}



