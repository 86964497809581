import { VesselType } from '@rhim/rest';
import { TFunction, useTranslation } from 'react-i18next';

export const getVesselLabel = (t: TFunction<'shared'>, vesselType: VesselType) => {
  switch (vesselType) {
    case VesselType.Rh:
      return t('vesselNames.rhDegasser', { ns: 'shared' });
    case VesselType.Bof:
      return t('vesselNames.bof', { ns: 'shared' });
    case VesselType.Ladle:
      return t('vesselNames.ladle', { ns: 'shared' });
    case VesselType.Eaf:
      return t('vesselNames.eaf', { ns: 'shared' });
    case VesselType.Rhl:
      return t('vesselNames.rhLower', { ns: 'shared' });
    case VesselType.Cou:
      return t('vesselNames.cou', { ns: 'shared' });
    case VesselType.Crk:
      return t('vesselNames.crk', { ns: 'shared' });
    case VesselType.Bfr:
      return t('vesselNames.bfr', { ns: 'shared' });
    case VesselType.Aod:
      return t('vesselNames.aod', { ns: 'shared' });
    case VesselType.Srf:
      return t('vesselNames.srf', { ns: 'shared' });
    case VesselType.Hml:
      return t('vesselNames.hml', { ns: 'shared' });
    default:
      throw new Error(`Unsupported translation for vessel type : ${vesselType}`);
  }
};

export function useVesselLabel(): (vesselType: VesselType) => string {
  const { t } = useTranslation('shared');

  return (vesselType) => getVesselLabel(t, vesselType);
}
