import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  size?: Property.Height;
  fill?: Property.Color;
  title?: string;
  role?: string;
}

export const HmlIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ size, fill, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <path
        fill={fill}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21 10a.985.985 0 0 0-.824.467l.818-7.356A1 1 0 0 0 20 2V2h-5a1 1 0 0 0-.8.4L12 5.333 9.8 2.4A1 1 0 0 0 9 2H4a1 1 0 0 0-.994 1.11l.818 7.357A.985.985 0 0 0 3 10a1 1 0 0 0-1 1v2a1 1 0 0 0 2 0h.105l.901 8.11A1 1 0 0 0 6 22h12a1 1 0 0 0 .994-.89l.901-8.11H20a1 1 0 0 0 2 0v-2a1 1 0 0 0-1-1zm-5.5-6h3.383L17.71 14.558c-.372.032-.76.065-.997.065-1.423 0-2.654-.452-3.84-.888-.63-.232-1.247-.458-1.873-.61-1.28-.312-2.308-.116-3.405.092-.456.087-.924.176-1.428.232L5.117 4H8.5l2.7 3.6a1 1 0 0 0 1.6 0L15.5 4z"
      />
    </svg>
  );
};

HmlIcon.defaultProps = {
  size: '24',
  fill: settings.colors.Primary.Grey_8,
};
