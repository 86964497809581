import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  size?: Property.Height;
  fill?: Property.Color;
  title?: string;
  role?: string;
}

export const HmlIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ size, fill, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 16 16" {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <path
        fill={fill}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.5 0a1 1 0 0 0-.994 1.107L1.137 7H0v3h1.459l.547 5.107A1 1 0 0 0 3 16h10a1 1 0 0 0 .994-.893L14.541 10H16V7h-1.137l.631-5.893A1 1 0 0 0 14.5 0H10a1 1 0 0 0-.832.445L8 2.197 6.832.445A1 1 0 0 0 6 0H1.5zm10.995 10.325L13.387 2h-2.852L8.832 4.555a1 1 0 0 1-1.664 0L5.465 2H2.613l.804 7.506c.253-.058.492-.123.726-.187.868-.236 1.669-.453 2.857-.217.626.124 1.243.31 1.872.5 1.126.338 2.292.688 3.623.723z"
      />
    </svg>
  );
};

HmlIcon.defaultProps = {
  size: '16',
  fill: settings.colors.Primary.Grey_8,
};
