export enum VesselType {
  Bof = 'BOF',
  Rh = 'RH',
  Eaf = 'EAF',
  Ladle = 'Ladle',
  Hml = 'HML',
  Crk = 'CRK',
  Rhl = 'RHL',
  Aod = 'AOD',
  Bfr = 'BFR',
  Srf = 'SRF',
  Cou = 'COU',
}
