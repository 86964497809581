import { VesselType } from '@rhim/rest';
import {
  RHIMAPOCustomerManagementWebV2ModelsVesselPropertiesBofVesselPropertiesDto,
  RHIMAPOCustomerManagementWebV2ModelsVesselPropertiesCrkVesselPropertiesDto,
  RHIMAPOCustomerManagementWebV2ModelsVesselPropertiesEafVesselPropertiesDto,
  RHIMAPOCustomerManagementWebV2ModelsVesselPropertiesRhlVesselPropertiesDto,
  RHIMAPOCustomerManagementWebV2ModelsVesselPropertiesRhVesselPropertiesDto,
} from '@rhim/rest/customerManagement';
import { specific } from '@rhim/utils';
import { ExpressiveQueryKey, QueryFunctionContext, useQuery, UseQueryOptions } from '@tanstack/react-query';
import { AxiosRequestConfig } from 'axios';

import { API } from '../api';

// eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
type Payload =
  // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
  | RHIMAPOCustomerManagementWebV2ModelsVesselPropertiesCrkVesselPropertiesDto
  // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
  | RHIMAPOCustomerManagementWebV2ModelsVesselPropertiesBofVesselPropertiesDto
  // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
  | RHIMAPOCustomerManagementWebV2ModelsVesselPropertiesEafVesselPropertiesDto
  // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
  | RHIMAPOCustomerManagementWebV2ModelsVesselPropertiesRhlVesselPropertiesDto
  // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
  | RHIMAPOCustomerManagementWebV2ModelsVesselPropertiesRhVesselPropertiesDto;

export const useVesselProperties = <T extends Payload>(
  vesselType: string,
  vesselId: UUID,
  options?: {
    configuration?: Omit<UseQueryOptions<Payload, unknown, T, ReturnType<typeof getKey>>, 'queryKey' | 'queryFn'>;
    axiosConfiguration?: AxiosRequestConfig;
  }
) => useQuery(getKey(vesselId, vesselType), (context) => queryFn(context, options?.axiosConfiguration), options?.configuration);

const getKey = (vesselId: UUID, vesselType: string) =>
  specific<ExpressiveQueryKey>()([
    {
      domain: 'customerManagement',
      scope: 'vessel-properties',
      entity: 'detail',
      vesselId,
      vesselType,
    },
  ]);

const queryFn = (
  { queryKey: [{ vesselId, vesselType }], signal }: QueryFunctionContext<ReturnType<typeof getKey>>,
  axiosConfiguration?: AxiosRequestConfig
) => {
  switch (vesselType) {
    case VesselType.Cou:
      return API.vesselProperties.getVesselsVesselidCou(vesselId, { signal, ...axiosConfiguration }).then((response) => response.data);
    case VesselType.Crk:
      return API.vesselProperties.getVesselsVesselidCrk(vesselId, { signal, ...axiosConfiguration }).then((response) => response.data);
    case VesselType.Ladle:
      return API.vesselProperties.getVesselsVesselidLadle(vesselId, { signal, ...axiosConfiguration }).then((response) => response.data);
    case VesselType.Hml:
      return API.vesselProperties.getVesselsVesselidHml(vesselId, { signal, ...axiosConfiguration }).then((response) => response.data);
    case VesselType.Bof:
      return API.vesselProperties.getVesselsVesselidBof(vesselId, { signal, ...axiosConfiguration }).then((response) => response.data);
    case VesselType.Eaf:
      return API.vesselProperties.getVesselsVesselidEaf(vesselId, { signal, ...axiosConfiguration }).then((response) => response.data);
    case VesselType.Rh:
      return API.vesselProperties.getVesselsVesselidRh(vesselId, { signal, ...axiosConfiguration }).then((response) => response.data);
    case VesselType.Rhl:
      return API.vesselProperties.getVesselsVesselidRhl(vesselId, { signal, ...axiosConfiguration }).then((response) => response.data);
    case VesselType.Aod:
      return API.vesselProperties.getVesselsVesselidAod(vesselId, { signal, ...axiosConfiguration }).then((response) => response.data);
    case VesselType.Bfr:
      return API.vesselProperties.getVesselsVesselidBfr(vesselId, { signal, ...axiosConfiguration }).then((response) => response.data);
    case VesselType.Srf:
      return API.vesselProperties.getVesselsVesselidSrf(vesselId, { signal, ...axiosConfiguration }).then((response) => response.data);
    default:
      throw new Error(`useVesselProperties, unexpected vesselType : '${vesselType}'`);
  }
};

useVesselProperties.getKey = getKey;
useVesselProperties.queryFn = queryFn;
