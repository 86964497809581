/* import-sort-ignore */
/* eslint-disable unused-imports/no-unused-imports */
/* eslint-disable @typescript-eslint/no-unused-vars */

// due to the fact that FeatureFlags are set per environment (by replacing the experimentalFeatures array within the file),
// it might happen that the FeatureFlag import is unused. We can ignore this situation
// this prevents the following error from happening: TS6133: 'FeatureFlag' is declared but its value is never read.
// Alternatively, the compiler setting for noUnusedLocals could be turned off in tsconfig.base.json, but this would affect all projects

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
// import { FeatureFlag } from '../settings';

/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
// This file can be replaced during build by using the `fileReplacements` array.
// When building for production, this file is replaced with `environment.prod.ts`.

/**
 * Values will be replaced with placeholder during the CI build.
 * Since placeholders do not conform to the type definition,
 * values defined as string literals must be a @ts-ignore before them.
 */
export const environment: APO.Environment = {
  // @ts-ignore
  logLevel: 'debug',
  production: true,
  authority: 'https://rhimb2cnp02.b2clogin.com/customer-dv.rhimagnesita.com/',
  // @ts-ignore
  loginPolicy: 'B2C_1A_signup_signin_hmd',
  logRocketId: '<placeholder>',
  clientId: '9b07970b-4a20-4604-bd79-086044d24a5f',
  scopes: ['https://customer-dv.rhimagnesita.com/9b07970b-4a20-4604-bd79-086044d24a5f/api'],
  // @ts-ignore
  experimentalFeatures: [],
  allowExtensions: false,
};
