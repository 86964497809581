import {
  HmlIcon as HmlIcon16,
  LadleIcon as LadleIcon16,
  VesselAODIcon as VesselAODIcon16,
  VesselBFRIcon as VesselBFRIcon16,
  VesselBOFIcon as VesselBOFIcon16,
  VesselCOUIcon,
  VesselCRKIcon as VesselCRKIcon16,
  VesselEAFIcon as EAFIcon16,
  VesselRHIcon as VesselRHIcon16,
  VesselRHLowerIcon as VesselRHLowerIcon16,
  VesselSRFIcon as VesselSRFIcon16,
} from '@rhim/icons/16';
import {
  EAFIcon as EAFIcon24,
  HmlIcon as HmlIcon24,
  LadleIcon as LadleIcon24,
  RHLowerIcon as RHLowerIcon24,
  VesselAODIcon as VesselAODIcon24,
  VesselBFRIcon as VesselBFRIcon24,
  VesselBOFIcon as VesselBOFIcon24,
  VesselCOUIcon as VesselCOUIcon24,
  VesselCRKIcon as VesselCRKIcon24,
  VesselRHIcon as VesselRHIcon24,
  VesselSRFIcon as VesselSRFIcon24,
} from '@rhim/icons/24';
import { Logger } from '@rhim/logging';
import { VesselType } from '@rhim/rest';
import {
  aodSelectorButtonDataCenter,
  bfrSelectorButtonDataCenter,
  bofSelectorButtonDataCenter,
  couSelectorButtonDataCenter,
  crkSelectorButtonDataCenter,
  eafSelectorButtonDataCenter,
  hotMetalLadleSelectorButtonDataCenter,
  ladleSelectorButtonDataCenter,
  rhLowerVesselSelectorButtonDataCenter,
  rhSelectorButtonDataCenter,
  srfSelectorButtonDataCenter,
} from '@rhim/test-ids';
import { isDefined } from '@rhim/utils';

const log = new Logger({
  context: 'Vessel types',
  logLevel: 'warn',
});

export type VesselIconSize = '16' | '24';
const defaultSize: VesselIconSize = '24';
const defaultColor = 'currentColor';

type Props = {
  [key in VesselType]: {
    dataTestId: string;
    getIcon: (color?: string, size?: VesselIconSize) => React.ReactElement;
  };
};

export const SUPPORTED_VESSEL_TYPES: Props = {
  [VesselType.Aod]: {
    dataTestId: aodSelectorButtonDataCenter,
    getIcon: (color = defaultColor, size = defaultSize) => (size === '16' ? <VesselAODIcon16 fill={color} /> : <VesselAODIcon24 fill={color} />),
  },
  [VesselType.Bfr]: {
    dataTestId: bfrSelectorButtonDataCenter,
    getIcon: (color = defaultColor, size = defaultSize) => (size === '16' ? <VesselBFRIcon16 fill={color} /> : <VesselBFRIcon24 fill={color} />),
  },
  [VesselType.Srf]: {
    dataTestId: srfSelectorButtonDataCenter,
    getIcon: (color = defaultColor, size = defaultSize) => (size === '16' ? <VesselSRFIcon16 fill={color} /> : <VesselSRFIcon24 fill={color} />),
  },
  [VesselType.Rh]: {
    dataTestId: rhSelectorButtonDataCenter,
    getIcon: (color = defaultColor, size = defaultSize) => (size === '16' ? <VesselRHIcon16 fill={color} /> : <VesselRHIcon24 fill={color} />),
  },
  [VesselType.Rhl]: {
    dataTestId: rhLowerVesselSelectorButtonDataCenter,
    getIcon: (color = defaultColor, size = defaultSize) => (size === '16' ? <VesselRHLowerIcon16 fill={color} /> : <RHLowerIcon24 fill={color} />),
  },
  [VesselType.Bof]: {
    dataTestId: bofSelectorButtonDataCenter,
    getIcon: (color = defaultColor, size = defaultSize) => (size === '16' ? <VesselBOFIcon16 fill={color} /> : <VesselBOFIcon24 fill={color} />),
  },
  [VesselType.Cou]: {
    dataTestId: couSelectorButtonDataCenter,
    getIcon: (color = defaultColor, size = defaultSize) => (size === '16' ? <VesselCOUIcon fill={color} /> : <VesselCOUIcon24 fill={color} />),
  },
  [VesselType.Crk]: {
    dataTestId: crkSelectorButtonDataCenter,
    getIcon: (color = defaultColor, size = defaultSize) => (size === '16' ? <VesselCRKIcon16 fill={color} /> : <VesselCRKIcon24 fill={color} />),
  },
  [VesselType.Ladle]: {
    dataTestId: ladleSelectorButtonDataCenter,
    getIcon: (color = defaultColor, size = defaultSize) => (size === '16' ? <LadleIcon16 fill={color} /> : <LadleIcon24 fill={color} />),
  },
  [VesselType.Hml]: {
    dataTestId: hotMetalLadleSelectorButtonDataCenter,
    getIcon: (color = defaultColor, size = defaultSize) => (size === '16' ? <HmlIcon16 fill={color} /> : <HmlIcon24 fill={color} />),
  },
  [VesselType.Eaf]: {
    dataTestId: eafSelectorButtonDataCenter,
    getIcon: (color = defaultColor, size = defaultSize) => (size === '16' ? <EAFIcon16 fill={color} /> : <EAFIcon24 fill={color} />),
  },
};

export const getVesselIcon = (vesselType: VesselType, color?: string, size?: VesselIconSize): React.ReactElement | undefined => {
  const vesselItem = SUPPORTED_VESSEL_TYPES[vesselType];

  if (!isDefined(vesselItem)) {
    log.warn(`VesselTypeSelection, getVesselIcon, unsupported vessel type : ${vesselType}`);

    return undefined;
  }

  return vesselItem.getIcon(color, size);
};
